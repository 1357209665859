import { useNavigate } from "react-router-dom";
import LogoGleeph from "../images/Full.svg";
import LogoB2B from "../images/b2b/logoRelay.png";
import cst from "../utils/constant";
import Back from "../images/flecheback_white.svg";

const Header = (props) => {
  var { withBack } = props;
  var city = cst.PDV_RELAY[cst.ID_STORE];
  if (withBack) {
    return <HeaderWithBack />;
  } else {
    return <HeaderHome city={city} />;
  }
};

const HeaderWithBack = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="headerWithBack"
      style={{ backgroundColor: cst.COLOR_TOP_BAR }}
    >
      <div onClick={() => navigate(-1)} className="backHeader">
        <img src={Back} alt="back" className="iconBackHeader" />
      </div>
      <img className="headerLogoB2BWithBack" src={LogoB2B} alt="logo" />
      <span className="headerTextMid">Recommandations par </span>
      <img className="logoGleephHeader" src={LogoGleeph} alt="logoGleeph" />
    </div>
  );
};

const HeaderHome = (props) => {
  const { city } = props;
  return (
    <div className="header" style={{ backgroundColor: cst.COLOR_TOP_BAR }}>
      <img className="headerLogoB2B" src={LogoB2B} alt="logo" />
      <span className="headerTextCity">{city}</span>
    </div>
  );
};

export default Header;
