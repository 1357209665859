// eslint-disable-next-line
export default {
  clients_themes: [
    { type: "theme", value: "Histoire", id: "Histoire" },
    { type: "theme", value: "Beaux livres", id: "Beaux livres" },
    { type: "theme", value: "Roman graphique", id: "Roman graphique" },
    { type: "theme", value: "Horreur", id: "Horreur" },
    { type: "theme", value: "Fantasy", id: "Fantasy" },
    { type: "theme", value: "Polar/Thriller", id: "Polar/Thriller" },
    { type: "theme", value: "Aventure/Action", id: "Aventure/Action" },
    { type: "theme", value: "Seinen", id: "Seinen" },
    { type: "theme", value: "Adaptation", id: "Adaptation" },
    { type: "theme", value: "Jeunesse", id: "Jeunesse" },
    { type: "theme", value: "Science fiction", id: "Science fiction" },
    { type: "theme", value: "Shonen", id: "Shonen" },
    { type: "theme", value: "Reportage/société", id: "Reportage/société" },
    { type: "theme", value: "Humour", id: "Humour" },
    { type: "theme", value: "Erotique", id: "Erotique" },
    { type: "theme", value: "Contes et legendes", id: "Contes et legendes" },
    { type: "theme", value: "Musique", id: "Musique" },
    { type: "theme", value: "Sport", id: "Sport" },
    { type: "theme", value: "Fantastique", id: "Fantastique" },
    { type: "theme", value: "Biographie", id: "Biographie" },
    { type: "theme", value: "Shojo", id: "Shojo" },
    { type: "theme", value: "Drame", id: "Drame" },
    { type: "theme", value: "Western", id: "Western" },
    { type: "theme", value: "Asie", id: "Asie" },
  ],
  autocompleteBook: [
    {
      type: "book",
      value:
        "Harry Potter. Vol. 1. Harry Potter \u00e0 l'\u00e9cole des sorciers, J.K. Rowling",
      id: "GL-3260050666312",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 2. Harry Potter et la chambre des secrets, J.K. Rowling",
      id: "GL-3260050667401",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 3. Harry Potter et le prisonnier d'Azkaban, J.K. Rowling",
      id: "GL-3260050668569",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 4. Harry Potter et la coupe de feu, J.K. Rowling",
      id: "GL-3260050678612",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 5. Harry Potter et l'ordre du Ph\u00e9nix, J.K. Rowling",
      id: "GL-9781781101070",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 6. Harry Potter et le prince de Sang-M\u00eal\u00e9, J.K. Rowling",
      id: "GL-9781781101087",
    },
    {
      type: "book",
      value:
        "Harry Potter. Vol. 7. Harry Potter et les reliques de la mort, J.K. Rowling",
      id: "GL-9781781101094",
    },
    {
      type: "book",
      value:
        "Harry Potter. Harry Potter et l'enfant maudit : parties un et deux : le texte de la pi\u00e8ce, Jack Thorne",
      id: "GL-9781781105542",
    },
    {
      type: "book",
      value: "Harry Potter : le livre de coloriage, ",
      id: "GL-9782012036963",
    },
    {
      type: "book",
      value:
        "Harry Potter et les reliques de la mort : Edition Serpentard, J. K. Rowling",
      id: "GL-9782075118446",
    },
  ],
  autocompleteAuthor: [
    { type: "authors", value: "Harry", id: "GL-9782213725048" },
    { type: "authors", value: "Harry Roselmack", id: "GL-9782889538232" },
    { type: "authors", value: "Harry J Studer", id: "GL-9798391260271" },
  ],
  autocompleteTheme: [
    { type: "theme", value: "harry", id: "harry" },
    { type: "theme", value: "harrysta", id: "harrysta" },
    { type: "theme", value: "harrypotter", id: "harrypotter" },
  ],
  recos: [
    {
      title: "Naruto : \u00e9dition Hokage. Vol. 15",
      authors: "Masashi Kishimoto",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7b1edd21e87a45a4f4fa4284217ea061e544fcb32afa699ad86fe94764a4b20a-300",
      description:
        "Gaara se transforme en hideuse cr\u00e9ature sous les yeux de Sasuke qui l'affronte avec d\u00e9termination. Mais tout \u00e0 coup, Sasuke s'\u00e9croule, terrass\u00e9 par le pouvoir de la marque maudite. Naruto et ses compagnons tentent de le tirer de ce mauvais pas, mais Gaara n'en finit pas de se m\u00e9tamorphoser.",
      edition: "Kana",
      publishing_date: 2023,
      id_ean: 9782505116783,
      clil: "3771",
      price: "15.50",
      stock: 1,
      series_name: "naruto edition hokage",
      link: 0,
    },
    {
      title: "My hero academia : Team up mission. Vol. 4",
      authors: "Yoko Akiyama",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bdd8fe5b898dc107b2f52a06a8046825d804022bb9066967fe041cded35317ea-300",
      description:
        "\u00c9pisode 4\n \n\nOn ne s'ennuie jamais \u00e0 Yuei, car les missions s'encha\u00eenent \u00e0 un rythme effr\u00e9n\u00e9\u00a0! Apr\u00e8s avoir aid\u00e9 Roro et Lala \u00e0 offrir des lunettes d'aviateur \u00e0 leur fr\u00e8re Rody, qui \u00e9tait venu rendre visite \u00e0 Izuku, les apprentis h\u00e9ros peuvent retrouver leur vie paisible de lyc\u00e9ens... enfin, presque\u00a0!\n\nEn effet, Neito d\u00e9fie la seconde A dans un test de courage au beau milieu de la nuit, et en pleine for\u00eat... le but\u00a0? Tenter de terroriser l'\u00e9quipe adverse et r\u00e9ussir \u00e0 lui faire perdre son sang-froid\u00a0! Seulement, les choses ne vont pas se passer exactement comme pr\u00e9vu...\n\nLe spin-off officiel My Hero Academia Team Up Mission est l'occasion de d\u00e9couvrir des histoires 100 % in\u00e9dites et de voir en action des \u00e9quipes inattendues\u00a0! Un must pour tous les fans de My Hero Academia\u00a0!",
      edition: "Ki-oon",
      publishing_date: 2023,
      id_ean: 9791032713174,
      clil: "3771",
      price: "6.95",
      stock: 1,
      series_name: "my hero academia team up mission",
      link: 0,
    },
    {
      title: "Edens Zero. Vol. 26. Un monde heureux",
      authors: "Hiro Mashima",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/4503203da7004446ab832cc1a9ac5ef66f580949c910a841d9800810824a5ec2-300",
      description:
        "Edenszero\n \n\nRachel, la m\u00e8re de Rebecca, est sortie de son long sommeil. Elle annonce un avenir terrifiant\u00a0: Mother s'appr\u00eate \u00e0 mourir et cela entra\u00eenera la fin du genre humain. Afin d'\u00e9viter cela, tout l'\u00e9quipage de l'Edens Zero d\u00e9cide de partir pour un voyage sans retour pour le dernier monde, \u00ab\u00a0l'univers Zero\u00a0\u00bb.\n\nLe r\u00e9cit reprend l\u00e0 o\u00f9 tout a commenc\u00e9, \u00e0 Granbell. Shiki et Rebecca, puis Happy, voil\u00e0 l'heure des retrouvailles\u00a0!\n \n\nUn nouveau d\u00e9part pour une aventure qui doit leur permettre de sauver le monde\u00a0!",
      edition: "Pika",
      publishing_date: 2023,
      id_ean: 9782811680510,
      clil: "3771",
      price: "7.20",
      stock: 1,
      series_name: "edens zero",
      link: 0,
    },
    {
      title: "Jujutsu kaisen : coffret tome 20",
      authors: "Gege Akutami",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/c61caff3fb768a536403cecc596da9a900961e5948f4099aaa93e53bce98da4f-300",
      description:
        "\u00c9dition prestige\n \n\nInclus dans ce coffret\u00a0: le tome 20 avec une jaquette alternative r\u00e9versible\n moule\n sceau\n 13 cartes illustr\u00e9es\n Mini-Mechamaru - lisi\u00e8re du supplice - \u00e9p\u00e9e",
      edition: "Ki-oon",
      publishing_date: 2023,
      id_ean: 9791032713426,
      clil: "3771",
      price: "34.90",
      stock: 1,
      series_name: "jujutsu kaisen",
      link: 0,
    },
    {
      title: "Undead Unluck. Vol. 12",
      authors: "Yoshifumi Tozuka",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5eee59462e4edbb72732c630946111e2f4037cbbadf1d8e6d2fd417db897b40b-300",
      description:
        "L'univers n'est pas pr\u00eat \u00e0 \u00e7a\u00a0!\n \n\n\u00c0 cause de l'attaque surprise de Ruin, F\u00fbko est entre la vie et la mort. Gr\u00e2ce \u00e0 l'ajout de Ghost, le concept de la mort est modifi\u00e9 et l'\u00e2me de F\u00fbko parvient \u00e0 subsister. Malheureusement, Ruin et Ghost s'\u00e9n emparent. Afin de sauver F\u00fbko, Andy quitte l'Union et se lance dans un nouveau p\u00e9riple...",
      edition: "Kana",
      publishing_date: 2023,
      id_ean: 9782505116691,
      clil: "3771",
      price: "7.10",
      stock: 1,
      series_name: "undead unluck",
      link: 0,
    },
    {
      title: "Black Butler. Vol. 32",
      authors: "Yana Toboso",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/40108edc0e12d4bf3965583f8ac8216f6b950e7ca8f33e9f5147ad029f66341d-300",
      description:
        "Black Butler\n \n\nDes troupes sont sur le point d'\u00eatre extermin\u00e9es dans des r\u00e9gions recul\u00e9es des \u00c9tats-Unis\n\nUn majordome \u00e9nigmatique appara\u00eet soudain avec un panier \u00e0 pique-nique devant l'unique survivant.\n\nSur le champ de bataille de la cuisine, les mains gant\u00e9es de blanc frappent le post\u00e9rieur du guerrier qui se d\u00e9bat lamentablement.\n\nSavourez ce manga tr\u00e8s spartiate...",
      edition: "Kana",
      publishing_date: 2023,
      id_ean: 9782505121428,
      clil: "3771",
      price: "7.10",
      stock: 1,
      series_name: "black butler",
      link: 0,
    },
    {
      title: "Shaman King 0. Vol. 1",
      authors: "Hiroyuki Takei",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2a91a323ad05fc028a79746e4a5096bb150f5b38b752339de3591785258e0200-300",
      description:
        "Shaman King 0\n \n\nLe pr\u00e9quel de la s\u00e9rie culte\u00a0!\n \n\nLes shamans relient notre monde \u00e0 l'au-del\u00e0. Tous les 500 ans, ils participent au Shaman Fight. Le vainqueur de ce tournoi deviendra un dieu si puissant qu'il pourra changer le monde.\n\nYoh, Ren, Horohoro ou encore Lyserg sont des shamans qui entreront plus tard dans la l\u00e9gende. Les histoires de Shaman King 0 se passent avant leur Shaman Fight et montrent la haine, les h\u00e9sitations, les d\u00e9cisions et \u00e9galement les douleurs qui les ont anim\u00e9s...",
      edition: "Kana",
      publishing_date: 2022,
      id_ean: 9782505088554,
      clil: "3771",
      price: "7.70",
      stock: 1,
      series_name: "shaman king 0",
      link: 0,
    },
    {
      title: "Radiant. Vol. 17",
      authors: "Tony Valente",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1857f648d77e493cfcd587511bf61da55dca7e8e1ef774993e4221483f780329-300",
      description:
        "\u00ab\u00a0Navr\u00e9, je ne Peux pas vous laisser d\u00e9cimer les n\u00f4tres sans r\u00e9agir... Sinon, quel mauvais guide je ferais\u00a0!\u00a0\u00bb\n \nAdh\u00e8s",
      edition: "Ankama",
      publishing_date: 2022,
      id_ean: 9791033514084,
      clil: "3771",
      price: "7.95",
      stock: 1,
      series_name: "radiant",
      link: 0,
    },
    {
      title: "Bleach. Vol. 74. The death and the strawberry",
      authors: "Taito Kubo",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6ad3d5567486a42e070036a9798f6fe5c05b6387593a0102ecf7273dca5a1c52-300",
      description:
        "Suite et fin des aventures d'Ichigo Kurosaki, qui poss\u00e8de le don de voir les esprits.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344021910,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_name: "bleach",
      link: 0,
    },
    {
      title: "Blue exorcist. Vol. 2",
      authors: "Kazue Kato",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7e271bc48ac7b2bf4e1b95d3e9aa2ee4327e49739d30dc05f5e5881b4affcd9d-300",
      description:
        "Sous l'oeil attentif de son fr\u00e8re Yukio, Rin poursuit son apprentissage du m\u00e9tier d'exorciste. Mais au cours d'un stage sp\u00e9cial cens\u00e9 pr\u00e9parer les \u00e9tudiants \u00e0 l'examen d'\u00e9t\u00e9, de puissantes entit\u00e9s d\u00e9moniaques parviennent \u00e0 s'introduire sur les lieux\u00a0!",
      edition: "Crunchyroll",
      publishing_date: 2010,
      id_ean: 9782849659267,
      clil: "3771",
      price: "7.15",
      stock: 1,
      series_name: "blue exorcist",
      link: 0,
    },
  ],
  my_book: {
    title:
      "One Piece : \u00e9dition originale. Vol. 104. Momonosuk\u00e9 Kozuki, shogun du pays des Wa",
    authors: "Eiichiro Oda",
    imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/cfaeb822edd8ef268201d0bc6243c651eca6ead28f396b908390b8a64da5392e-300",
    description:
      "Luffy met tout en oeuvre pour redonner le sourire aux habitants du pays des Wa et lib\u00e9rer l'\u00eele de la domination de Kaido et Orochi. Il poursuite ses aventures pour mettre la main sur le tr\u00e9sor One Piece.",
    edition: "Gl\u00e9nat",
    publishing_date: 2023,
    id_ean: 9782344052174,
    clil: "3771",
    price: "6.99",
    stock: 1,
    series_name: "one piece edition originale",
    link: 0,
  },
  glid_search: "GL-9782344052174",
  theme_search: "",
  books_by_author: [
    {
      glid: "GL-9782344057186",
      title: "One Piece : episode A. Vol. 1. Ace",
      authors: "Eiichiro Oda, Boichi",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/8ff81636cce52d05182b471538f675f8741442c71675ecc6998fbc7fb32db8ea-300",
      description:
        "Inspir\u00e9 du roman One piece roman Ace ce manga est un spin-off mettant en sc\u00e8ne le personnage d'Ace.",
      edition: "Gl\u00e9nat",
      publishing_date: 2023,
      id_ean: 9782344057186,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782344057186",
      series_name: "one piece episode a",
      link: 0,
    },
    {
      glid: "GL-9782344052020",
      title: "One piece : color walk. Vol. 9. Tiger",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2ea614a826064b3ee7ec83955f7ea812450b51cd80fe9be7e4c42ceeedc8fdd9-300",
      description:
        "Une s\u00e9lection des meilleurs travaux d'Eiichiro Oda. Sont regroup\u00e9s des planches originales, des illustrations pleine page et des croquis en noir et blanc d\u00e9voilant l'envers de la cr\u00e9ation des personnages de One piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2023,
      id_ean: 9782344052020,
      clil: "3771",
      price: "23.45",
      stock: 1,
      series_id: "GL-9782344008409",
      series_name: "one piece color walk",
      link: 0,
    },
    {
      glid: "GL-9782331053993",
      title: "One Piece : \u00e9dition originale. Vol. 100. Le fluide royal",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e29bc8fd4633a38b0ade7e353d1bcc80a972318935d103e3685c250da3a102ae-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344049020,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331052897",
      title:
        "One Piece : \u00e9dition originale. Vol. 99. Luffy au chapeau de paille",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3bc1a0ac69b0a804f50367b17d605cb113f9cebdbf7b8e45a8f1eaa33e9c1748-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344048740,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331052071",
      title:
        "One Piece : \u00e9dition originale. Vol. 98. Les neuf r\u00f4nins",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e6461292ca36f8dd44b7a3ca1671ee57970ac814789aad57e3a2ccaca56cffbb-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344047569,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331050022",
      title:
        "One Piece : \u00e9dition originale. Vol. 96. Bouillir, tel est le propre du Oden",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/c0af518ff3b7f98c5a8770869728d215ec461288a554257cd976ce0b8339f208-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344044698,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331049194",
      title: "One Piece : \u00e9dition originale. Vol. 95. L'aventure d'Oden",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/29fcaf3c0eb6196a4fd3ac888aa6c576dfa015fca1d8dd70acf6fadb6c3292fe-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344043301,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344042632",
      title:
        "One Piece : \u00e9dition originale. Vol. 94. Le r\u00eave des guerriers",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6695699d58d583bfc1e19d1db83ca5463952a175fd9be5cc8305aa31d8d9c8d0-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344042632,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331046872",
      title:
        "One Piece : \u00e9dition originale. Vol. 93. La coqueluche du village d'Ebisu",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a8f066bb17ab31d7918a08b02d9d803eb067b033816e789824dcfc053e7c5247-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344041451,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344038819",
      title:
        "One Piece : \u00e9dition originale. Vol. 92. La grande courtisane Komurasaki",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2f5e1d3af1a8987cbf3f299ab96f6c8fb126d8674e898858e704438064f67648-300",
      description:
        "La col\u00e8re de Luffy s'abat sur Kaido, une puissante cr\u00e9ature transform\u00e9e en dragon, lorsque ce dernier attaque ses amis. De nouvelles aventures l'attendent.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344038819,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344037102",
      title:
        "One Piece : \u00e9dition originale. Vol. 91. Aventure au pays des samoura\u00efs",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e99b8eea684cd1d5fdcb0434c2b53a61ec95fafece4f19df6b87f0c453ca1f54-300",
      description: "La suite des aventures de Luffy.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344037102,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331042775",
      title:
        "One Piece : \u00e9dition originale. Vol. 90. La terre sainte de Marie Joie",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/c8308c5b7836c3c195e9b5d0c025b9cb5d716a0d46d352b3b05bdf3b0d2dd3d1-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344033593,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331041358",
      title: "One Piece : \u00e9dition originale. Vol. 89. Bad end musical",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1c7e584604d17be2df5b31fba1acf23bb9157969187168c9d038f2c97e617398-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344033586,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344030059",
      title: "One Piece : \u00e9dition originale. Vol. 88. Lionne",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5f17a284c83e2922bdf692c28399442a3bfe8149ea411940ca3c4e5365bdc757-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344030059,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331037603",
      title: "One Piece : \u00e9dition originale. Vol. 87. Impitoyable",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3def07e6d893e3554dc81b37a1f439aad97368c721226f672e4e81c420e089b3-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344030042,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344027523",
      title:
        "One Piece : \u00e9dition originale. Vol. 86. Op\u00e9ration r\u00e9gicide",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/793446d65c4449daf63edf8f847f9b6365a8644630627090ce58097e60d0dfa7-300",
      description:
        "Suite des aventures de Luffy \u00e0 la poursuite de One Piece. Capone \u00e9labore son plan pour assassiner la coriace Big Mom. Il doit toucher le point faible s'il veut avoir une chance de la blesser.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344027523,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036972",
      title: "One Piece : \u00e9dition originale. Vol. 85. Menteur",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e74e0aac075a2d3db71598975caed8ba324027294458227a6460b8c004f79b44-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344027509,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036965",
      title: "One Piece : \u00e9dition originale. Vol. 84. Luffy versus Sanji",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e8c6ace902ed532e06ba818f853259571ee6c1dd842d37fea5d065d38b9b1b18-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344025314,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036958",
      title: "One Piece : \u00e9dition originale. Vol. 83. Charlotte Linlin",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/689a67f9ceec178130133d286fa6f03b7c433513a0fe4da64bef2db663f708eb-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344023211,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036941",
      title:
        "One Piece : \u00e9dition originale. Vol. 82. Un monde en pleine agitation",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f9dd3675a3ebfaf6a521d28880807c314e19e017040b71d143411e3a5a2f3b26-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344020432,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036934",
      title:
        "One Piece : \u00e9dition originale. Vol. 81. A la rencontre de Ma\u00eetre Chavip\u00e8re",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bce42bfe37817e8d4208cac0241e7c4a9cb2002a428370fa8861f33fb157d6b1-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344018286,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036927",
      title:
        "One Piece : \u00e9dition originale. Vol. 80. Vers une bataille sans pr\u00e9c\u00e9dent",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2bf1ede7b901f0078845482a07001b736ec26c8b05a4a08d4243d21d6b8d493a-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344017470,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331036910",
      title: "One Piece : \u00e9dition originale. Vol. 79. Lucy !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a9f59e4af392a33a8b04f40b82e7de4c528d9be50235d7ae676091b8da347d5d-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344016374,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034664",
      title: "One Piece : \u00e9dition originale. Vol. 78. L'ic\u00f4ne du mal",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/999a4020b422140e71d8c28c32ea5965c70ef2ce3d1de9a2cb2552bbbf017cfc-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344013229,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034657",
      title: "One Piece : \u00e9dition originale. Vol. 77. Smile",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3085ac6d202c60b353815a7d953fef05fc7c69760216d9ce4a4a31613001c04c-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008430,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034640",
      title: "One Piece : \u00e9dition originale. Vol. 76. Poursuis ta route !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f0719ef95a53dfd459fe0ebf3a363ec11ce39cb546343fe32a2aad7dd5f335d6-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008423,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034633",
      title: "One Piece : \u00e9dition originale. Vol. 75. Ma gratitude",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/36bbc861a7e1b52ebe12870999e92e316df7a040a1c28c0e1bb70295d1210f7c-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008393,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034626",
      title:
        "One Piece : \u00e9dition originale. Vol. 74. Je serai toujours \u00e0 tes c\u00f4t\u00e9s",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9014a89f05e5643e2891a8333efc5f3439633f67af0d174d175dfe407d359510-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344006597,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034619",
      title:
        "One Piece : \u00e9dition originale. Vol. 73. L'op\u00e9ration Dressrosa S.O.P.",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1bd7536ef870c4ab59d7b723e75d607b636881fe2dffef2442a12d534bccf172-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344006450,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344002032",
      title:
        "One Piece : \u00e9dition originale. Vol. 59. La fin de Portgas D. Ace",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/539b60df68fd33e53f21f5a814c77bd8ab901d9350052db72c332b6c7c2d04c3-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002032,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344002018",
      title: "One Piece : \u00e9dition originale. Vol. 57. Guerre au sommet",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/67ac955f327525b7dd66690d431a334c067153cfc9a9dd576697b7ced1ce1672-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002018,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344002001",
      title: "One Piece : \u00e9dition originale. Vol. 56. Merci pour tout",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f825bf07ba3d62c0adc684742d0777531b94df0ea9cfd4f1f60a707d470f6ab4-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002001,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331029578",
      title:
        "One Piece : \u00e9dition originale. Vol. 58. L'\u00e8re de Barbe blanche",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f0459317eccb56734dc4bb5347ab2d7040a74cc4f33dc5284d41b9f512986fe4-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002025,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331029592",
      title: "One Piece : \u00e9dition originale. Vol. 60. Petit fr\u00e8re",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3cc81bcd57f993106e1f8b09d2cca53c1a168f2de33c25772d3e933c81a7fb87-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002049,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034602",
      title:
        "One Piece : \u00e9dition originale. Vol. 72. Les oubli\u00e9s de Dressrosa",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5bb4bff3c5af6c1a10da3e89a7c09509304b2f79e81e29f5b6dfb8693e9bbf5d-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344004319,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331027512",
      title: "One Piece : \u00e9dition originale. Vol. 51. Les onze supernovae",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ac0ebc800af9019f722f27e2b848fd8a386ebc7eaa17b17d15116f961dfb1136-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001950,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331027550",
      title: "One Piece : \u00e9dition originale. Vol. 55. Des travs en enfer",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/4a54072bb941d05a3b9905c43ebdc0ced0224359380fbe841db19e94bd355eb8-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001998,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344001981",
      title:
        "One Piece : \u00e9dition originale. Vol. 54. Plus personne ne m'arr\u00eatera",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/59c16407fe0c84052f54640cb89fa21e11e790e53d5cd9238c3124c23213913d-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001981,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331027529",
      title: "One Piece : \u00e9dition originale. Vol. 52. Roger & Rayleigh",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e2b7da5a4ecb85d746678e6d8d910c4bf5f889b2f5ba2d4f0b8ec97657f81317-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001967,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331027536",
      title:
        "One Piece : \u00e9dition originale. Vol. 53. Le temp\u00e9rament d'un roi",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2886fc6512f0c2bb5aceb01c56d509c76a193ce028d08ac53aa64d72558b0abb-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001974,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034596",
      title:
        "One Piece : \u00e9dition originale. Vol. 71. Le colis\u00e9e de tous les dangers",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9e9a1bad2d633d21fc002590f5b4b9e5a8ba3ed6b2151f9da71bafba6e6be95f-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723499347,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344001905",
      title:
        "One Piece : \u00e9dition originale. Vol. 46. Aventure sur l'\u00eele fant\u00f4me",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/744d1b2882f28a4949b0b6dc6df083eb8c17158adf025ad0ca4339d692d946b1-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001905,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331027505",
      title:
        "One Piece : \u00e9dition originale. Vol. 50. De nouveau face au mur",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3fc2bea4412f988a378355bf600e10ad06db744276235186754e10ca1241fbdb-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001943,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331023859",
      title:
        "One Piece : \u00e9dition originale. Vol. 43. La l\u00e9gende du h\u00e9ros",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bdd7dcd015856573b0d694fb75efb04d4393f76c344480ae6109cca3b0193a4a-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001875,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344001936",
      title: "One Piece : \u00e9dition originale. Vol. 49. Nightmare Luffy",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d8171eaea66332a1a736a958daa2f8ca07e03f2841ade21bf8a57f0d75a7135e-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001936,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344001929",
      title: "One Piece : \u00e9dition originale. Vol. 48. L'aventure d'Odz",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e5a9590e67f261ddc2ee3cb0af5e90b49835af3abb3e29f581afac2feab71558-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001929,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331026010",
      title:
        "One Piece : \u00e9dition originale. Vol. 45. Je comprends ce que vous ressentez",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6dc3336e5d44840a692fc4f0369b447309587b8e807bf7ab08e7b7b0fc7e5811-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001899,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331026560",
      title:
        "One Piece : \u00e9dition originale. Vol. 47. Temps couvert avec chutes d'os par moments",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d37c7884b6f795d9ce709472679318ddda984b8d5684332e69d9b8b29dfadbf4-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001912,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331023835",
      title:
        "One Piece : \u00e9dition originale. Vol. 41. D\u00e9claration de guerre",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/30009bd6b18b0f4786e98f3a92d13e48eacb2f0c5fcf8ca14139532b0f4c151f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001851,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782344001868",
      title:
        "One Piece : \u00e9dition originale. Vol. 42. Les pirates contre le CP9",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6494ffa3830216463fe4875bee2822aeaa83222421aff802196e935b3f99a910-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001868,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331034589",
      title:
        "One Piece : \u00e9dition originale. Vol. 70. Doflamingo sort de l'ombre",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d4caea78b5c92f9a35907afd9842caa74ab42d1c4dd4e8b2589c25cbab1108a6-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723499330,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723498654",
      title: "One Piece : \u00e9dition originale. Vol. 37. Monsieur Tom",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b907f290cf927be47b87b79d7844424743652af461de3403f8973d3315c262bf-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498654,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331023811",
      title:
        "One Piece : \u00e9dition originale. Vol. 39. Op\u00e9ration sauvetage",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ee3545c61e9da9df9d21300a2899ab726b45027b1ea3296b72da661bf9fe401f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498678,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723498661",
      title: "One Piece : \u00e9dition originale. Vol. 38. Rocketman !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/19b8ed393082de6b8e048638ded734b2a834f342e9e3c9c78470a1d491c00fb4-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498661,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723498685",
      title: "One Piece : \u00e9dition originale. Vol. 40. Gear",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b2c843ceaaf0b08d7d26ffbbe655a0869efb71c2a2b5d006779d8387c37c1eb8-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498685,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331019982",
      title: "One Piece : \u00e9dition originale. Vol. 35. Capitaine",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9f371dcf7537d825ef33225b64517366f49d69efdb23c86bf8e10d37a2775a14-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498630,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331017759",
      title: "One Piece : \u00e9dition originale. Vol. 30. Capriccio",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f6940e02329324f19e4251e06525ddfd9376a739a396bfaf18817583e09cad7a-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494861,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723494786",
      title: "One Piece : \u00e9dition originale. Vol. 22. Hope !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d9a335e2aa7d29a69059eba916a6531fc16d3fb999fc51917c6e37a33acff9aa-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494786,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331017742",
      title: "One Piece : \u00e9dition originale. Vol. 29. Oratorio",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/be99f7f9971950a8a07a699d4f944f6976448c9b12f15a24e4910494bed75389-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494854,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723498609",
      title: "One Piece : \u00e9dition originale. Vol. 32. Love song",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7155aabc9987c0ee9061109818bb935c772364ae6bc4c703123195f2c3ec6d7c-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498609,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331015953",
      title:
        "One Piece : \u00e9dition originale. Vol. 25. L'homme qui valait 100 millions",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/90505cf71c01a934f3b8df9bb02270f547324009da7175a3e7f95fc395000369-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494816,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331015946",
      title:
        "One Piece : \u00e9dition originale. Vol. 24. Croire en ses r\u00eaves",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2dc25e01b08e6454f5b5ad019d75dd0511934f86751b0cc09d7cfcb99b2e741d-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494809,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331015939",
      title: "One Piece : \u00e9dition originale. Vol. 23. L'aventure de Vivi",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e380e4e106b18cee54b9d62905f2794cbc29ee2e647ff59207b57302525d7966-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494793,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331017735",
      title:
        "One Piece : \u00e9dition originale. Vol. 28. Wiper le d\u00e9mon furieux",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a62682a048a490394820b14c1e2fa2a8eaafd48b81b3f41a6bf7c123d7271231-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494847,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723498623",
      title:
        "One Piece : \u00e9dition originale. Vol. 34. Water Seven, la cit\u00e9 sur l'eau",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/68bf48df41a545a6d6678372fa756ee63b5cdec9a883efad4c43fb69d31fd62f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498623,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331017711",
      title:
        "One Piece : \u00e9dition originale. Vol. 26. Aventure sur l'\u00eele de Dieu",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2db00ff1ae4e271d2c1d75a04783a55756084545054d5cc0df8333841c7ea16c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494823,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331018602",
      title: "One Piece : \u00e9dition originale. Vol. 31. Nous sommes l\u00e0",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ac43ed66625644045abacbb257c2a216bd8117f9b853231fc1782f9381d72c6c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498593,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782723494830",
      title: "One Piece : \u00e9dition originale. Vol. 27. Pr\u00e9lude",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2fd492b329e6e4a2d4444749929f298179b788e46663d2e24f27abc5456fd29c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494830,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331018626",
      title: "One Piece : \u00e9dition originale. Vol. 33. Davy back fight !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9ac1c655eda48c2a3f808b31dcb188277cda55eec9d37c99db97c8d7466663c3-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498616,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331033032",
      title: "One Piece : \u00e9dition originale. Vol. 69. Sad",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bec5e0b708c8f74948e40b5b0602a01217d8cf18c2e3e6d396be576a3f013f77-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498234,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331033025",
      title:
        "One Piece : \u00e9dition originale. Vol. 68. Alliance entre pirates",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d93553472a00574f8b2c41cb996d161f8975467db63e4872ef18fec92d046b40-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723496766,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331010156",
      title: "One Piece : \u00e9dition originale. Vol. 7. Vieux machin",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7aa42ac53869baafea1f19d501dced22debee76d66a1cecb3a80ed38fdbd5407-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723489942,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331011610",
      title:
        "One Piece : \u00e9dition originale. Vol. 11. Le plus grand bandit d'East Blue",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/255dc9e7d6a25dc81799c382698a2560e5ecb127e27166c7bbb4d13b5100caf1-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492560,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013416",
      title: "One Piece : \u00e9dition originale. Vol. 14. Instinct",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f03f03227d5bfca9520897667f55bbbba21949a9a83338eb3574ae301b5ae106-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492591,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331011580",
      title: "One Piece : \u00e9dition originale. Vol. 8. Je ne mourrai pas !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b74d653442af05143e263a25f38de65508a91901acca8a010713fec818bb89d9-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723489959,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013423",
      title: "One Piece : \u00e9dition originale. Vol. 15. Droit devant !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9be190f4379402efaf9414d73799b435a0469d8d3b39a9e1fd3b2eacc1beb946-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492607,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331011627",
      title:
        "One Piece : \u00e9dition originale. Vol. 12. Et ainsi d\u00e9buta la l\u00e9gende",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b038ee9448bf00e744527eba3948e95d03f9bafc6f9d5ae46550053f921bc617-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492577,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013744",
      title: "One Piece : \u00e9dition originale. Vol. 16. Successeurs",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/450e1957b058ac01f439f02d6ca6ea3bb96138f489034599f4bb022b76c5ce72-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494724,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331033018",
      title: "One Piece : \u00e9dition originale. Vol. 67. Cool fight",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/52637c4ed3d33e61965acbc72663e75743c29757b1b9c0a571b485b031311f03-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723495738,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013751",
      title:
        "One Piece : \u00e9dition originale. Vol. 17. Les cerisiers d'Hiluluk",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/40c57c228e1ac1cb9d7fcf882a72aaef005c19f430457f9ed4207f7cbba01716-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494731,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013775",
      title: "One Piece : \u00e9dition originale. Vol. 19. R\u00e9bellion",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1bd0939b81da95aea67369d13cf947e7e326629569228d3a7a577d26f26e9260-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494755,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331011597",
      title: "One Piece : \u00e9dition originale. Vol. 9. Larmes",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/88022f6160cd16adfe5a3710fc10bdea8011c21e4e3d1a2c9c9f224b1dc6abef-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique, mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492539,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013782",
      title:
        "One Piece : \u00e9dition originale. Vol. 20. Bataille d\u00e9cisive \u00e0 Alubarna",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ef404b09340e6fdcc8a73edf2ec472ca6b671e92c99d887ec817c5a288ce31c5-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494762,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331013768",
      title:
        "One Piece : \u00e9dition originale. Vol. 18. Ace entre en sc\u00e8ne",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b52d029187093306a2143d5727c0e0385bf6e7ccc47d2935d99031a1bba132b5-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494748,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331033001",
      title: "One Piece : \u00e9dition originale. Vol. 66. Vers le soleil",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/07fff698f605cebdafb64e3633e08db58ad260cdf5f38861b2ca20d7e151726a-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723493260,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331032998",
      title: "One Piece : \u00e9dition originale. Vol. 65. Table rase",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b639b18363bd3ed61e91759e5b6dd5bdb2ec25d793f35294af05f0fa92992b2d-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723493062,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331032981",
      title: "One Piece : \u00e9dition originale. Vol. 64. 100.000 vs 10",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/418189a9de4a6224e5f050f144e2bb611fd0b6783e60a7371e37c5d32f12676b-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487702,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331032912",
      title: "One Piece : \u00e9dition originale. Vol. 63. Otohime et Tiger",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f83e2d62ea888ce8e35692ca7adf0d67253bff0d54f53e4866db5f58566fc10f-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487696,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331032905",
      title:
        "One Piece : \u00e9dition originale. Vol. 62. P\u00e9riple sur l'\u00eele des hommes poissons",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/8bdd229364d83e7fa309d99d3fb639e2c18894213332302f527de761969b5c2a-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affonter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487689,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      glid: "GL-9782331029608",
      title:
        "One Piece : \u00e9dition originale. Vol. 61. A l'aube d'une grande aventure vers le Nouveau Monde",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e6e8e15baf8d4202acfe8e902cddaa9288ca5d25d37aa5ef7527613f8de5bd51-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723486682,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
  ],
  books_by_serie: [
    {
      title: "One Piece : \u00e9dition originale. Vol. 7. Vieux machin",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7aa42ac53869baafea1f19d501dced22debee76d66a1cecb3a80ed38fdbd5407-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723489942,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 8. Je ne mourrai pas !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b74d653442af05143e263a25f38de65508a91901acca8a010713fec818bb89d9-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723489959,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 9. Larmes",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/88022f6160cd16adfe5a3710fc10bdea8011c21e4e3d1a2c9c9f224b1dc6abef-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique, mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492539,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 11. Le plus grand bandit d'East Blue",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/255dc9e7d6a25dc81799c382698a2560e5ecb127e27166c7bbb4d13b5100caf1-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492560,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 12. Et ainsi d\u00e9buta la l\u00e9gende",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b038ee9448bf00e744527eba3948e95d03f9bafc6f9d5ae46550053f921bc617-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492577,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 14. Instinct",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f03f03227d5bfca9520897667f55bbbba21949a9a83338eb3574ae301b5ae106-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492591,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 15. Droit devant !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9be190f4379402efaf9414d73799b435a0469d8d3b39a9e1fd3b2eacc1beb946-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723492607,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 16. Successeurs",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/450e1957b058ac01f439f02d6ca6ea3bb96138f489034599f4bb022b76c5ce72-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494724,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 17. Les cerisiers d'Hiluluk",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/40c57c228e1ac1cb9d7fcf882a72aaef005c19f430457f9ed4207f7cbba01716-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494731,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 18. Ace entre en sc\u00e8ne",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b52d029187093306a2143d5727c0e0385bf6e7ccc47d2935d99031a1bba132b5-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494748,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 19. R\u00e9bellion",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1bd0939b81da95aea67369d13cf947e7e326629569228d3a7a577d26f26e9260-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494755,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 20. Bataille d\u00e9cisive \u00e0 Alubarna",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ef404b09340e6fdcc8a73edf2ec472ca6b671e92c99d887ec817c5a288ce31c5-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723494762,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 22. Hope !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d9a335e2aa7d29a69059eba916a6531fc16d3fb999fc51917c6e37a33acff9aa-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494786,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 23. L'aventure de Vivi",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e380e4e106b18cee54b9d62905f2794cbc29ee2e647ff59207b57302525d7966-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494793,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 24. Croire en ses r\u00eaves",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2dc25e01b08e6454f5b5ad019d75dd0511934f86751b0cc09d7cfcb99b2e741d-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494809,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 25. L'homme qui valait 100 millions",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/90505cf71c01a934f3b8df9bb02270f547324009da7175a3e7f95fc395000369-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494816,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 26. Aventure sur l'\u00eele de Dieu",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2db00ff1ae4e271d2c1d75a04783a55756084545054d5cc0df8333841c7ea16c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494823,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 27. Pr\u00e9lude",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2fd492b329e6e4a2d4444749929f298179b788e46663d2e24f27abc5456fd29c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de contorsionner son corps \u00e9lastique mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures. Manga.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494830,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 28. Wiper le d\u00e9mon furieux",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a62682a048a490394820b14c1e2fa2a8eaafd48b81b3f41a6bf7c123d7271231-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494847,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 29. Oratorio",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/be99f7f9971950a8a07a699d4f944f6976448c9b12f15a24e4910494bed75389-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494854,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 30. Capriccio",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f6940e02329324f19e4251e06525ddfd9376a739a396bfaf18817583e09cad7a-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723494861,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 31. Nous sommes l\u00e0",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ac43ed66625644045abacbb257c2a216bd8117f9b853231fc1782f9381d72c6c-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498593,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 32. Love song",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/7155aabc9987c0ee9061109818bb935c772364ae6bc4c703123195f2c3ec6d7c-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498609,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 33. Davy back fight !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9ac1c655eda48c2a3f808b31dcb188277cda55eec9d37c99db97c8d7466663c3-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il affronte de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498616,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 34. Water Seven, la cit\u00e9 sur l'eau",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/68bf48df41a545a6d6678372fa756ee63b5cdec9a883efad4c43fb69d31fd62f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498623,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 35. Capitaine",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9f371dcf7537d825ef33225b64517366f49d69efdb23c86bf8e10d37a2775a14-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498630,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 37. Monsieur Tom",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b907f290cf927be47b87b79d7844424743652af461de3403f8973d3315c262bf-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498654,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 38. Rocketman !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/19b8ed393082de6b8e048638ded734b2a834f342e9e3c9c78470a1d491c00fb4-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498661,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 39. Op\u00e9ration sauvetage",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ee3545c61e9da9df9d21300a2899ab726b45027b1ea3296b72da661bf9fe401f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498678,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 40. Gear",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b2c843ceaaf0b08d7d26ffbbe655a0869efb71c2a2b5d006779d8387c37c1eb8-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498685,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 41. D\u00e9claration de guerre",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/30009bd6b18b0f4786e98f3a92d13e48eacb2f0c5fcf8ca14139532b0f4c151f-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001851,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 42. Les pirates contre le CP9",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6494ffa3830216463fe4875bee2822aeaa83222421aff802196e935b3f99a910-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001868,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 43. La l\u00e9gende du h\u00e9ros",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bdd7dcd015856573b0d694fb75efb04d4393f76c344480ae6109cca3b0193a4a-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001875,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 45. Je comprends ce que vous ressentez",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6dc3336e5d44840a692fc4f0369b447309587b8e807bf7ab08e7b7b0fc7e5811-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001899,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 46. Aventure sur l'\u00eele fant\u00f4me",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/744d1b2882f28a4949b0b6dc6df083eb8c17158adf025ad0ca4339d692d946b1-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001905,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 47. Temps couvert avec chutes d'os par moments",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d37c7884b6f795d9ce709472679318ddda984b8d5684332e69d9b8b29dfadbf4-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001912,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 48. L'aventure d'Odz",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e5a9590e67f261ddc2ee3cb0af5e90b49835af3abb3e29f581afac2feab71558-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001929,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 49. Nightmare Luffy",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d8171eaea66332a1a736a958daa2f8ca07e03f2841ade21bf8a57f0d75a7135e-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001936,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 50. De nouveau face au mur",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3fc2bea4412f988a378355bf600e10ad06db744276235186754e10ca1241fbdb-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001943,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 51. Les onze supernovae",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ac0ebc800af9019f722f27e2b848fd8a386ebc7eaa17b17d15116f961dfb1136-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001950,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 52. Roger & Rayleigh",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e2b7da5a4ecb85d746678e6d8d910c4bf5f889b2f5ba2d4f0b8ec97657f81317-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001967,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 53. Le temp\u00e9rament d'un roi",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2886fc6512f0c2bb5aceb01c56d509c76a193ce028d08ac53aa64d72558b0abb-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001974,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 54. Plus personne ne m'arr\u00eatera",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/59c16407fe0c84052f54640cb89fa21e11e790e53d5cd9238c3124c23213913d-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001981,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 55. Des travs en enfer",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/4a54072bb941d05a3b9905c43ebdc0ced0224359380fbe841db19e94bd355eb8-300",
      description:
        "Lufy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344001998,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 56. Merci pour tout",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f825bf07ba3d62c0adc684742d0777531b94df0ea9cfd4f1f60a707d470f6ab4-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002001,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 57. Guerre au sommet",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/67ac955f327525b7dd66690d431a334c067153cfc9a9dd576697b7ced1ce1672-300",
      description:
        "Luffy, gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis, il est capable de se contorsionner mais ne peut plus nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates dans de nombreuses aventures.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002018,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 58. L'\u00e8re de Barbe blanche",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f0459317eccb56734dc4bb5347ab2d7040a74cc4f33dc5284d41b9f512986fe4-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002025,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 59. La fin de Portgas D. Ace",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/539b60df68fd33e53f21f5a814c77bd8ab901d9350052db72c332b6c7c2d04c3-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002032,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 60. Petit fr\u00e8re",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3cc81bcd57f993106e1f8b09d2cca53c1a168f2de33c25772d3e933c81a7fb87-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme-caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344002049,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 61. A l'aube d'une grande aventure vers le Nouveau Monde",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e6e8e15baf8d4202acfe8e902cddaa9288ca5d25d37aa5ef7527613f8de5bd51-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723486682,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 62. P\u00e9riple sur l'\u00eele des hommes poissons",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/8bdd229364d83e7fa309d99d3fb639e2c18894213332302f527de761969b5c2a-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affonter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487689,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 63. Otohime et Tiger",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f83e2d62ea888ce8e35692ca7adf0d67253bff0d54f53e4866db5f58566fc10f-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487696,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 64. 100.000 vs 10",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/418189a9de4a6224e5f050f144e2bb611fd0b6783e60a7371e37c5d32f12676b-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2012,
      id_ean: 9782723487702,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 65. Table rase",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/b639b18363bd3ed61e91759e5b6dd5bdb2ec25d793f35294af05f0fa92992b2d-300",
      description:
        "Lufy, un gar\u00e7on espi\u00e8gle, r\u00eave de devenir le roi des pirates en trouvant le One Piece, un fabuleux tr\u00e9sor. Il a aval\u00e9 par m\u00e9garde un fruit d\u00e9moniaque qui l'a transform\u00e9 en homme caoutchouc. Depuis il est capable de contorsionner son corps \u00e9lastique mais il a perdu la facult\u00e9 de nager. Avec l'aide de ses amis, il va devoir affronter de redoutables pirates.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723493062,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 66. Vers le soleil",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/07fff698f605cebdafb64e3633e08db58ad260cdf5f38861b2ca20d7e151726a-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723493260,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 67. Cool fight",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/52637c4ed3d33e61965acbc72663e75743c29757b1b9c0a571b485b031311f03-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723495738,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 68. Alliance entre pirates",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d93553472a00574f8b2c41cb996d161f8975467db63e4872ef18fec92d046b40-300",
      description:
        "Suite des aventures de Lufy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2013,
      id_ean: 9782723496766,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 69. Sad",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bec5e0b708c8f74948e40b5b0602a01217d8cf18c2e3e6d396be576a3f013f77-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723498234,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 70. Doflamingo sort de l'ombre",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/d4caea78b5c92f9a35907afd9842caa74ab42d1c4dd4e8b2589c25cbab1108a6-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723499330,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 71. Le colis\u00e9e de tous les dangers",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9e9a1bad2d633d21fc002590f5b4b9e5a8ba3ed6b2151f9da71bafba6e6be95f-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782723499347,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 72. Les oubli\u00e9s de Dressrosa",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5bb4bff3c5af6c1a10da3e89a7c09509304b2f79e81e29f5b6dfb8693e9bbf5d-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2014,
      id_ean: 9782344004319,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 73. L'op\u00e9ration Dressrosa S.O.P.",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1bd7536ef870c4ab59d7b723e75d607b636881fe2dffef2442a12d534bccf172-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344006450,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 74. Je serai toujours \u00e0 tes c\u00f4t\u00e9s",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9014a89f05e5643e2891a8333efc5f3439633f67af0d174d175dfe407d359510-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344006597,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 75. Ma gratitude",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/36bbc861a7e1b52ebe12870999e92e316df7a040a1c28c0e1bb70295d1210f7c-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008393,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 76. Poursuis ta route !",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f0719ef95a53dfd459fe0ebf3a363ec11ce39cb546343fe32a2aad7dd5f335d6-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008423,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 77. Smile",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3085ac6d202c60b353815a7d953fef05fc7c69760216d9ce4a4a31613001c04c-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2015,
      id_ean: 9782344008430,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 78. L'ic\u00f4ne du mal",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/999a4020b422140e71d8c28c32ea5965c70ef2ce3d1de9a2cb2552bbbf017cfc-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344013229,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 79. Lucy !!",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a9f59e4af392a33a8b04f40b82e7de4c528d9be50235d7ae676091b8da347d5d-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344016374,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 80. Vers une bataille sans pr\u00e9c\u00e9dent",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2bf1ede7b901f0078845482a07001b736ec26c8b05a4a08d4243d21d6b8d493a-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2016,
      id_ean: 9782344017470,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 81. A la rencontre de Ma\u00eetre Chavip\u00e8re",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/bce42bfe37817e8d4208cac0241e7c4a9cb2002a428370fa8861f33fb157d6b1-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344018286,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 82. Un monde en pleine agitation",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/f9dd3675a3ebfaf6a521d28880807c314e19e017040b71d143411e3a5a2f3b26-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344020432,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 83. Charlotte Linlin",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/689a67f9ceec178130133d286fa6f03b7c433513a0fe4da64bef2db663f708eb-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344023211,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 84. Luffy versus Sanji",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e8c6ace902ed532e06ba818f853259571ee6c1dd842d37fea5d065d38b9b1b18-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2017,
      id_ean: 9782344025314,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 85. Menteur",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e74e0aac075a2d3db71598975caed8ba324027294458227a6460b8c004f79b44-300",
      description:
        "Suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344027509,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 86. Op\u00e9ration r\u00e9gicide",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/793446d65c4449daf63edf8f847f9b6365a8644630627090ce58097e60d0dfa7-300",
      description:
        "Suite des aventures de Luffy \u00e0 la poursuite de One Piece. Capone \u00e9labore son plan pour assassiner la coriace Big Mom. Il doit toucher le point faible s'il veut avoir une chance de la blesser.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344027523,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 87. Impitoyable",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3def07e6d893e3554dc81b37a1f439aad97368c721226f672e4e81c420e089b3-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344030042,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 88. Lionne",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5f17a284c83e2922bdf692c28399442a3bfe8149ea411940ca3c4e5365bdc757-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2018,
      id_ean: 9782344030059,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 89. Bad end musical",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/1c7e584604d17be2df5b31fba1acf23bb9157969187168c9d038f2c97e617398-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344033586,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 90. La terre sainte de Marie Joie",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/c8308c5b7836c3c195e9b5d0c025b9cb5d716a0d46d352b3b05bdf3b0d2dd3d1-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344033593,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 91. Aventure au pays des samoura\u00efs",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e99b8eea684cd1d5fdcb0434c2b53a61ec95fafece4f19df6b87f0c453ca1f54-300",
      description: "La suite des aventures de Luffy.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344037102,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 92. La grande courtisane Komurasaki",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2f5e1d3af1a8987cbf3f299ab96f6c8fb126d8674e898858e704438064f67648-300",
      description:
        "La col\u00e8re de Luffy s'abat sur Kaido, une puissante cr\u00e9ature transform\u00e9e en dragon, lorsque ce dernier attaque ses amis. De nouvelles aventures l'attendent.",
      edition: "Gl\u00e9nat",
      publishing_date: 2019,
      id_ean: 9782344038819,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 93. La coqueluche du village d'Ebisu",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a8f066bb17ab31d7918a08b02d9d803eb067b033816e789824dcfc053e7c5247-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344041451,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 94. Le r\u00eave des guerriers",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/6695699d58d583bfc1e19d1db83ca5463952a175fd9be5cc8305aa31d8d9c8d0-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344042632,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 95. L'aventure d'Oden",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/29fcaf3c0eb6196a4fd3ac888aa6c576dfa015fca1d8dd70acf6fadb6c3292fe-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344043301,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 96. Bouillir, tel est le propre du Oden",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/c0af518ff3b7f98c5a8770869728d215ec461288a554257cd976ce0b8339f208-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2020,
      id_ean: 9782344044698,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 98. Les neuf r\u00f4nins",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e6461292ca36f8dd44b7a3ca1671ee57970ac814789aad57e3a2ccaca56cffbb-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344047569,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title:
        "One Piece : \u00e9dition originale. Vol. 99. Luffy au chapeau de paille",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/3bc1a0ac69b0a804f50367b17d605cb113f9cebdbf7b8e45a8f1eaa33e9c1748-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344048740,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
    {
      title: "One Piece : \u00e9dition originale. Vol. 100. Le fluide royal",
      authors: "Eiichiro Oda",
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/e29bc8fd4633a38b0ade7e353d1bcc80a972318935d103e3685c250da3a102ae-300",
      description:
        "La suite des aventures de Luffy, toujours \u00e0 la recherche du tr\u00e9sor One Piece.",
      edition: "Gl\u00e9nat",
      publishing_date: 2021,
      id_ean: 9782344049020,
      clil: "3771",
      price: "6.99",
      stock: 1,
      series_id: "GL-9782331009532",
      series_name: "one piece edition originale",
      link: 0,
    },
  ],
  author_search: ["eiichiro oda"],
  serie_search: "one piece edition originale",
};
