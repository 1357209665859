import BookList from "./BookList";
import logojaime from "../images/J'aime.svg";
import WorkHorizontalList from "./WorkHorizontalList";
import author from "../images/AuteurBlack.svg";
import serie from "../images/Serie.svg";
import more from "../images/openMore.svg";
import { useNavigate } from "react-router-dom";
import helpers from "../utils/apiHelper";
import text from "../utils/lang/lang";
import cst from "../utils/constant";

const RecoBooksComp = (props) => {
  const {
    listBook,
    listBookAuthor,
    listSerie,
    glidRef,
    authorRef,
    themeRef,
    type,
  } = props;
  const navigate = useNavigate();
  if (listBook.length > 0 && listBookAuthor.length > 0) {
    if (type === "book") {
      return (
        <RecoBookB2B
          listBook={listBook}
          listBookAuthor={listBookAuthor}
          listSerie={listSerie}
          glidRef={glidRef}
          authorRef={authorRef}
          themeRef={themeRef}
          type={type}
          navigate={navigate}
        />
      );
    } else if (type === "author") {
      return (
        <RecoAuthorB2B
          listBook={listBook}
          listBookAuthor={listBookAuthor}
          listSerie={listSerie}
          glidRef={glidRef}
          authorRef={authorRef}
          themeRef={themeRef}
          type={type}
          navigate={navigate}
        />
      );
    }
  } else if (listBook.length > 0 && listBookAuthor.length === 0) {
    return <RecoBookThemeAutre listBook={listBook} type={type} />;
  }

  return <div></div>;
};

const RecoBookThemeAutre = (props) => {
  const { listBook, type } = props;
  return (
    <div>
      <div className="separateLineStyle" />
      <div className="containerListResultWoMargin">
        <div>
          {type !== "theme" && (
            <div style={{ marginBottom: 16 }}>
              <div>
                <span className="midTextReco">
                  <div className="logoJaimeContainer">
                    <img
                      src={logojaime}
                      alt="logojaime"
                      className="imageJaime"
                    />
                    {text.TITLE_RUBRIQUE_RECOS}
                  </div>
                </span>
              </div>
            </div>
          )}
          {listBook.length > 0 && <BookList listBook={listBook} />}
        </div>
      </div>
    </div>
  );
};

const RecoBookB2B = (props) => {
  const {
    listBook,
    listBookAuthor,
    listSerie,
    glidRef,
    authorRef,
    themeRef,
    type,
    navigate,
  } = props;
  return (
    <div>
      <div className="separateLineStyle" />
      <div className="containerWorkHorizontalAndText">
        <div className="containerScrollVertical">
          <span className="midTextReco">
            <div className="logoJaimeContainer">
              <img src={logojaime} alt="logojaime" className="imageJaime" />
              {text.TITLE_RUBRIQUE_RECOS}
            </div>
          </span>
          <div
            onClick={() => {
              helpers.fetchTrackSearch("more_reco", glidRef);
              navigate(
                `/${cst.ID_STORE}/datas/reco?author=${authorRef}&book=${glidRef}&theme=${themeRef}&type=${type}`
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={more} alt="more" />
          </div>
        </div>
        {listBook.length > 0 && (
          <WorkHorizontalList
            works={listBook}
            size={"default"}
            key="book"
            navigate={navigate}
          />
        )}
      </div>
      {listBookAuthor.length > 0 && (
        <div>
          <div className="separateLineStyle" />
          <div className="containerWorkHorizontalAndText">
            <div className="containerScrollVertical">
              <span className="midTextReco">
                <div className="logoJaimeContainer">
                  <img className="imageJaime" src={author} alt="item" />
                  {authorRef.length > 1
                    ? "Des mêmes auteurs"
                    : "Du même auteur"}
                </div>
              </span>
              <div className="containerAutoCompletion">
                <div
                  onClick={() => {
                    helpers.fetchTrackSearch("more_author", glidRef);
                    navigate(
                      `/${cst.ID_STORE}/datas/author?author=${authorRef}&book=${glidRef}&theme=${themeRef}&type=${type}`
                    );
                  }}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                >
                  <img src={more} alt="more" />
                </div>
              </div>
            </div>
            <WorkHorizontalList
              works={listBookAuthor}
              size={"default"}
              type="author"
              navigate={navigate}
            />
          </div>
        </div>
      )}
      {/* {listSerie.length > 0 && (
        <div>
          <div className="separateLineStyle" />
          <div className="containerWorkHorizontalAndText">
            <div className="containerScrollVertical">
              <span className="midTextReco">
                <div className="logoJaimeContainer">
                  <img className="imageJaime" src={serie} alt="item" />
                  {text.TITLE_RUBRIQUE_SERIES}
                </div>
              </span>
              <div
                onClick={() => {
                  helpers.fetchTrackSearch("more_serie", glidRef);
                  navigate(
                    `/${cst.ID_STORE}/datas/serie?author=${authorRef}&book=${glidRef}&theme=${themeRef}&type=${type}`
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={more} alt="more" />
              </div>
            </div>
            <WorkHorizontalList
              works={listSerie}
              size={"default"}
              key="serie"
              navigate={navigate}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

const RecoAuthorB2B = (props) => {
  const {
    listBook,
    listBookAuthor,
    listSerie,
    glidRef,
    authorRef,
    themeRef,
    type,
    navigate,
  } = props;
  return (
    <div>
      {listBookAuthor.length > 0 ? (
        <div>
          <div className="separateLineStyle" />
          <div className="containerWorkHorizontalAndText">
            <WorkHorizontalList
              works={listBookAuthor}
              size={"default"}
              type="author"
              navigate={navigate}
            />
          </div>
          <div className="recoAuthorButtonContainer">
            <div
              onClick={() => {
                helpers.fetchTrackSearch("more_author", glidRef);
                navigate(
                  `/${cst.ID_STORE}/datas/author?author=${authorRef}&book=${glidRef}&theme=${themeRef}&type=${type}`
                );
              }}
              className="recoAuthorButtonBorder"
            >
              <span className="recoAuthorButtonText">
                voir tous ses livres en stock
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="separateLineStyle" />
          <div className="recoAuthorContainerNoBook">
            <span className="recoAuthorTextNoBook">
              Aucun titre disponible.
            </span>
          </div>
        </div>
      )}
      <div className="separateLineStyle" />
      <div className="containerWorkHorizontalAndText">
        <div className="containerScrollVertical">
          <span className="midTextReco">
            <div className="logoJaimeContainer">
              <img src={logojaime} alt="logojaime" className="imageJaime" />
              {text.TITLE_RUBRIQUE_RECOS}
            </div>
          </span>
          <div
            onClick={() => {
              helpers.fetchTrackSearch("more_reco", glidRef);
              navigate(
                `/${cst.ID_STORE}/datas/reco?author=${authorRef}&book=${glidRef}&theme=${themeRef}&type=${type}`
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={more} alt="more" />
          </div>
        </div>
        {listBook.length > 0 && (
          <WorkHorizontalList
            works={listBook}
            size={"default"}
            key="book"
            navigate={navigate}
          />
        )}
      </div>
    </div>
  );
};

export default RecoBooksComp;
