import { useNavigate } from "react-router-dom";
import PriceAndPublisher from "../puresComponents/PriceAndPublisher";
import helpers from "../utils/apiHelper";
import close from "../images/close2.svg";
import DefaultCover from "../puresComponents/DefaultCover";
import cst from "../utils/constant";
import ClilRectangle from "../puresComponents/ClilRectangle";

function BookList(props) {
  let { listBook, type } = props;
  const navigate = useNavigate();

  return (
    <div className="ContainerBook">
      {listBook.map((book) => (
        <div
          onClick={() => {
            helpers.fetchTrackSearch("click_on_book_vertical", book.id_ean);
            book.link
              ? window.open(book.link)
              : navigate(`/${cst.ID_STORE}/datas/${book.id_ean}`);
          }}
          className="booklist-container"
          key={book.id_ean}
        >
          <Book book={book} type={type} />
        </div>
      ))}
    </div>
  );
}

const Book = (props) => {
  const { book, lite } = props;

  return (
    <div className="containerBookInfo" key={book.id_ean}>
      <DefaultCover imgs={book.imgs} />
      <div className="itemText">
        <p className="itemTitle">{book.title}</p>
        <p className="itemAuthor">
          {book.authors} • {book.publishing_date.split("T")[0]}
        </p>
        <ClilRectangle id={book.clil1} />
        {!lite && (
          <PriceAndPublisher edition={book.edition} price={book.price} />
        )}
      </div>
    </div>
  );
};

export default BookList;
