import HeartBookList from "../puresComponents/HeartBookList";
import WorkHorizontalList from "./WorkHorizontalList";
import text from "../utils/lang/lang";
import jaime from "../images/coupDecoeur.svg";

const CoupDeCoeur = (props) => {
  const { scrollable } = props;
  const heartBook = [
    {
      id: 1,
      id_ean: 9782370734952,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/2e9e92e51e8da2de6f27f4f30f7b8454e6d5ad9fc76f8204e19488bd7dbb52db-300",
      link: 0.0,
    },
    {
      id: 2,
      id_ean: 9782330191122,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/5f4cd2e378e1032e9f039ce5080b1c8c17656c87a067e6a8035b53bc58969630-300",
      link: 0.0,
    },
    {
      id: 3,
      id_ean: 9782253907770,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/efd2eea3e86f645eaeb5d8249e6d34b7dd56fa271890f8104e7b26a7d8ae2428-300",
      link: 0.0,
    },
    {
      id: 4,
      id_ean: 9791037113368,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/128b2079a8e0e16d0de578eca7d1ac9e2fefe931ea21f80582f9739b74c38cbb-300",
      link: 0.0,
    },
  ];

  return (
    <div className="coupdecoeurContainer">
      <div className="coupdecoeurTitleContainer">
        <img src={jaime} alt="icon" className="coupdecoeurIconJaimeContainer" />
        <span className="coupDeCoeurPolice">{text.TITLE_COUP_DE_COEUR}</span>
      </div>
      {heartBook.length > 0 &&
        (scrollable ? (
          <WorkHorizontalList works={heartBook} size={"coupDeCoeur"} />
        ) : (
          <HeartBookList heartBook={heartBook} />
        ))}
    </div>
  );
};

export default CoupDeCoeur;
