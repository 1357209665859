
async function clearImgs(res, with_limit=false) {
    //Check if Imgs URL are okay
    let res_w_validurl = [];
    await Promise.all(
      res.map(async (book) => {
        try {
          // Send a HEAD request to check the status
          const response = await fetch(book.imgs, { method: "HEAD" });
          // If the status code is in the 2xx range, consider it a success
          if (response.ok) {
            res_w_validurl.push(book);
          } else {
            console.log(`Broken URL: ${book.imgs}`);
          }
        } catch (error) {
          console.log(`Error checking URL: ${book.imgs}`);
          console.error(error);
        }
      })
    );

    //Order by distance desc
    res_w_validurl = res_w_validurl.sort((a, b) =>
      a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0
    );
    if(with_limit){
      res_w_validurl = res_w_validurl.slice(0, 10);
    }
    return res_w_validurl
}

export {

  clearImgs
};
