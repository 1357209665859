import data from "../data/data_test";
import bookreco from "../data/bookreco_test";
import cst from "./constant";
let controller;

var API = {
  fetchBook: async function (id) {
    return fetch(cst.BOOKSCREEN_API[cst.ENV] + id)
      .then((res) => res.json())
      .then(
        (result) => {
          let dataMerge = bookreco.book;
          dataMerge.additionalData = result.additionalData;
          dataMerge.data = result.data;
          dataMerge.similarBooks = result.similarBooks;
          dataMerge.comments = result.comments;
          return dataMerge;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchGetRecos: async function (
    type,
    book,
    author,
    searchTracking = "",
    resultAutocompletion = "",
    subject = "",
    tracking_consent = "false",
    options = {}
  ) {
    const { timeout = 5000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    let URL_API_RECO =
      cst.URL_API +
      "book/reco/v1/?max_authors=1&nbrecos=13&catalog_name=fnac_all-000000000000&type=add&name_store=" +
      cst.CLIENT_NAME +
      "&id_store=" +
      cst.ID_STORE +
      "&search_made=" +
      searchTracking +
      "&proposal_auto_completion=" +
      resultAutocompletion +
      "&subject=" +
      subject +
      "&tracking_consent=" +
      tracking_consent +
      "&";
    if (type === "book") {
      URL_API_RECO =
        URL_API_RECO +
        "reco_type=eans&input_ids=" +
        book +
        "&books=" +
        book +
        "&authors=";
    } else if (type === "authors") {
      URL_API_RECO =
        URL_API_RECO +
        "reco_type=authors&input_ids=" +
        author +
        "&books=&authors=" +
        author;
    }
    return fetch(URL_API_RECO, {
      ...options,
      signal: controller.signal,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      )
      .finally(clearTimeout(id));
  },
  fetchGetDatas: async function (
    book,
    author,
    theme,
    searchTracking = "",
    resultAutocompletion = "",
    subject = "",
    tracking_consent = "true",
    options = {}
  ) {
    const { timeout = 5000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 
    timeout);

    let URL_API_RECO = cst.URL_API + "book/datas/v1/?catalog_name=" +
      cst.ID_STORE + 
      "&type=add&name_store=" +
      cst.CLIENT_NAME.toLowerCase() +
      "&id_store=" +
      cst.ID_STORE + 
      "&search_made=" +
      searchTracking +
      "&proposal_auto_completion=" +
      resultAutocompletion +
      "&subject=" +
      subject +
      "&tracking_consent=" +
      tracking_consent 
    if (book) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=eans&input_ids=" +
        book +
        "&books=" +
        book +
        "&authors=&themes=";
    } else if (author) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=authors&input_ids=" +
        author +
        "&books=&authors=" +
        author + "&themes=";
    } else if (theme) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=themes&input_ids=" +
        theme +
        "&books=&authors=&themes=" + theme;
    }
    return fetch(
      URL_API_RECO, {
        ...options,
        signal: controller.signal,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      )
      .finally(clearTimeout(id));
  },
  fetchAutors: async function (id) {
    return data.books_by_author;
  },
  fetchSeries: async function (id) {
    return data.books_by_serie;
  },
  fetchThemes: async function (id) {
    return data.clients_themes;
  },
  fetchAutocomplete: async function (value) {
    // if (type_search === "book") {
    //   return data.autocompleteBook;
    // } else if (type_search === "author") {
    //   return data.autocompleteAuthor;
    // }
    if (controller) {
      controller.abort();
      console.log("Download aborted");
    }
    controller = new AbortController();
    const signal = controller.signal;
    return fetch(
      cst.URL_API +
        "search/v1/?search=" +
        value +
        "&type_search=book&type_search=author&type_search=theme",
      { signal }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      )
      .catch((err) => {
        console.error(`Download error: ${err.message}`);
      });
  },
  fetchTrackSearch: async function (type = "", element = "") {
    return fetch(
      cst.URL_API +
        "book/tracking/click/v1/?name_store=" +
        cst.CLIENT_NAME +
        "&id_store=" +
        cst.ID_STORE +
        "&type=" +
        type +
        "&subject=" +
        element
    );
  },
  fetchTrackReco: async function (
    books = "",
    authors = "",
    themes = "",
    stock = []
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        store: cst.CLIENT_NAME,
        id_store: cst.ID_STORE,
        books: books,
        authors: authors,
        themes: themes,
        bookReco: {
          nostock: [],
          stock: stock,
        },
        userid: cst.TEMP_USERID,
      }),
    };
    return fetch(cst.URL_API + "trackReco", requestOptions);
  },
};

export default API;
