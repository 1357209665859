import author from "../images/AuteurBlack.svg";
import themeLogo from "../images/TagBlack.svg";
import BookSearch from "./BookSearch";
import text from "../utils/lang/lang";

//Component pour render la partie author
const TitleReco = (props) => {
  const { type, search, book, lenBookAuthor } = props;
  if (type === "author") {
    return (
      <div>
        <div className="TopTextRecoBook" style={{ marginBottom: 16 }}>
          {lenBookAuthor > 0
            ? text.TITLE_RECOS_AUTHOR_1
            : text.TITLE_RECOS_AUTHOR_2}
          <div>
            <img className="itemTopTextReco" src={author} alt="item" />
            {search}
          </div>
        </div>
      </div>
    );
  } else if (type === "theme") {
    return (
      <div>
        <div className="TopTextRecoBook" style={{ marginBottom: 16 }}>
          {text.TITLE_RECOS_THEME_1}
          <div>
            {text.TITLE_RECOS_THEME_2}
            <img className="itemTopTextReco" src={themeLogo} alt="item" />
            {search}
          </div>
        </div>
      </div>
    );
  } else if (type === "book") {
    return (
      <div>
        <div className="TopTextRecoBook">{text.TITLE_RECOS_BOOK}</div>
        <BookSearch book={book} />
      </div>
    );
  }
};

export default TitleReco;
