import cst from "../utils/constant";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  const { redirect, text, icon, color } = props;
  const navigate = useNavigate();

  return (
    <div
      className="borderSubmitBtn shareLinkEnabled"
      style={{ background: color ? color : cst.COLOR_BUTTON }}
      onClick={() => window.open("https://www.relay.com/vote-voyageurslecteurs-2024/?mtm_campaign=Gleeph&mtm_source=Webapp&mtm_medium=display&mtm_content=bouton")}
    >
      {icon !== undefined && (
        <img src={icon} alt="iconshare" className="iconShareReco" />
      )}
      <span className="buttonCompText">{text}</span>
    </div>
  );
};

export default Button;
