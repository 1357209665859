import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import helpers from "../utils/apiHelper";
import model from "../models/model";
import BackGroundBook from "../components/BackGroundBook";
import AuthorPanel from "../puresComponents/AuthorPanel";
import Popularity from "../components/Popularity";
import ResumePanel from "../components/ResumePanel";
import FooterGleeph from "../puresComponents/FooterGleeph";
import Loading from "../puresComponents/Loading";
import CommentOnWork from "../components/CommentOnWork";
import cst from "../utils/constant";
import MentionsLegales from "../puresComponents/MentionsLegales";
import Header from "../puresComponents/Header";

//Composant regroupant tous les éléments de la page livre
export default function BookDetail() {
  const { id_reco } = useParams();
  const [book, setBook] = useState(model.book);
  const [loading, setLoading] = useState(true);
  const [enStock, setEnStock] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const resBook = await helpers.fetchBook(id_reco);
      if (resBook.message === "NotExist") {
        alert("Le livre n'existe pas");
      } else {
        setBook(resBook);
        setLoading(false);
        // const resStockAndPrice = await helpers.fetchIsInStock(id_reco);
        // setEnStock(parseInt(resStockAndPrice["stock"]) > 0 ? true : false);
        // setPrice(resStockAndPrice["price"]);
      }
    };
    fetchData();
  }, []);

  const { additionalData, data, comments } = book;
  const stats = additionalData.stats;
  const imgs = cst.WORK_PICTURE + data.image + cst.FIN_LIEN;
  data.description =
    data.description == null || data.description === undefined
      ? ""
      : data.description;
  return (
    <div>
      <Header withBack={true} />
      <div className="containerBlur">
        <div
          className="blurCover"
          style={data.image ? { backgroundImage: `url(${imgs})` } : null}
        />
      </div>
      <BackGroundBook
        id_ean={data.id}
        image={data.image}
        title={data.title}
        edition={data.edition}
        publishing_date={data.publicationYear}
        page={data.pages}
        clil={data.clilObj}
        loading={loading}
      />
      <div className="backgroundOpacityListBookInShelf" />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <AuthorPanel
              name={data.authors[0]}
              enStock={enStock}
              price={price}
            />
          </div>
          <div className="separateLine" />
          <div>
            <Popularity tags={stats} />
          </div>
          <div className="separateLine" />
          {data.description.length > 0 && (
            <ResumePanel description={data.description} />
          )}
          <div className="separateLine" />
          {stats.comments > 0 && (
            <div>
              <CommentOnWork
                commentInfo={comments}
                commentCount={stats.comments}
              />
            </div>
          )}
          <div className="separateLine" />
          <div className="bookDetailMarginGleephFooter">
            {/* <FooterB2B />
            <div className="separateLineStyle" /> */}
            <FooterGleeph />
          </div>
          <div className="separateLine" />
          {/* <ReglementConcours /> */}
          <MentionsLegales />
        </div>
      )}
      {/* { !isRegistered && (
        <div>
          <div>
            <FooterButton />
          </div>
        </div>
      )} */}
    </div>
  );
}
