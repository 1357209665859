import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import own from "../images/own.svg";
import ownMin from "../images/ownMin.svg";
import wish from "../images/wish.svg";
import wishMin from "../images/wishMin.svg";
import reading from "../images/reading.svg";
import readingMin from "../images/readingMin.svg";
import read from "../images/read.svg";
import readMin from "../images/readMin.svg";
import like from "../images/like.svg";
import likeMin from "../images/likeMin.svg";

const typeTags = {
  mini: {
    own: ownMin,
    wish: wishMin,
    reading: readingMin,
    read: readMin,
    like: likeMin,
  },
  normal: {
    own: own,
    wish: wish,
    reading: reading,
    read: read,
    like: like,
  },
};

const colorTags = {
  own: "#7AB9FF",
  wish: "#FFAE4A",
  reading: "#D59AED",
  read: "#89DA8E",
  like: "#F97E60",
};

const textTags = {
  own: "j'ai",
  wish: "wishlist",
  reading: "je lis",
  read: "j'ai lu",
  like: "j'aime",
};

//Component gérant le render des tags selon leurs type
const Tags = (props) => {
  const { type, nb } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: 720px)` })) {
    screen = "mini";
  }

  return (
    <div className="containerTag">
      <div className="circleTag" style={{ borderColor: colorTags[type] }}>
        <img src={typeTags[screen][type]} alt="Tag" />
      </div>
      <div
        className="nbTag"
        style={{
          backgroundColor: colorTags[type],
          marginLeft:
            screen === "mini" ? (cst.OS === "Android" ? 40 : 20) : null,
        }}
      >
        <span className="nbTagPolice">{nb}</span>
      </div>
      <div className="containerTextTag">
        <span className="textTagsPolice" style={{ color: colorTags[type] }}>
          {textTags[type]}
        </span>
      </div>
    </div>
  );
};

export default Tags;
