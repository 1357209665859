import React from "react";
import FooterGleeph from "../puresComponents/FooterGleeph";
import MentionsLegales from "../puresComponents/MentionsLegales";
import Header from "../puresComponents/Header";

export default function LegalNotice() {
  return (
    <div>
      <Header withBack={true} />

      <section style={{ paddingBottom: 80 }}>
        <div className="legalNoticeContainer">
          <div className="legalNotice">MENTIONS LEGALES</div>
          <p className="legalNoticeSubtitle">EDITEUR DU SITE :</p>
          <p className="legalNoticeText">
            Vous êtes actuellement connecté au site{" "}
            <a href="https://relay.gleeph.com/">https://relay.gleeph.com/</a>{" "}
            édité par la société F-451, SAS au capital de 50 277,10 euros, dont
            le siège social est situé 1 rue Alexander Fleming - 17 000 La
            Rochelle, immatriculée au Registre du Commerce et des Sociétés de La
            Rochelle sous le numéro 808 534 465.
            <br />
            Téléphone : 09 67 67 22 21
            <br />
            Pour toute remarque sur le fonctionnement du site, écrivez à
            l'administrateur du site :{" "}
            <a href="mailto:contact@gleeph.net">contact@gleeph.net</a>
          </p>
          <p>
            <span className="legalNoticeSubtitle">
              DIRECTEUR DE LA PUBLICATION :
            </span>{" "}
            Guillaume DEBAIG
          </p>
          <p className="legalNoticeSubtitle"> HEBERGEMENT :</p>
          <p className="legalNoticeText">
            Ce site est hébergé par la société Google Cloud France SARL, 8 Rue
            de Londres, 75009 PARIS.
          </p>
          <p className="legalNoticeText">
            Les serveurs de Google Cloud sont situés en Belgique.
          </p>
          <p className="legalNoticeText">
            L'utilisateur en ayant accès à ce site s'engage à se conformer aux
            présentes conditions d'utilisation.
          </p>
          <p className="legalNoticeSubtitle">DROITS D'AUTEUR :</p>
          <p className="legalNoticeText">
            Les marques et logos figurant sur ce site sont des marques déposées
            par F-451 ou LAGARDERE TRAVEL RETAIL ou leurs partenaires. Leur
            mention n'accorde en aucune manière une licence ou un droit
            d'utilisation quelconque desdites marques, qui ne peuvent donc être
            utilisées sans le consentement préalable et écrit d’F-451 ou des
            partenaires.
            <br />
            <br />
            L'ensemble des informations présentes sur ce site peut être
            téléchargé, reproduit, imprimé sous réserve de :<br />
            - n'utiliser de telles informations qu'à des fins personnelles et en
            aucune manière à des fins commerciales;
            <br />
            - ne pas modifier de telles informations ;<br />
            - reproduire sur toutes copies la mention des droits d'auteur de
            F-451 ou de ses partenaires.
            <br />
            <br />
            Toute autre utilisation est strictement interdite sans autorisation
            préalable et écrite de F-451.
          </p>
          <p className="legalNoticeSubtitle">CREDITS :</p>
          <p className="legalNoticeText">
            Visuels : FIGS SARL, 17 Rue des Panoyaux, 75020 Paris
          </p>
          <p className="legalNoticeSubtitle">RESPONSABILITÉ :</p>
          <p className="legalNoticeText">
            L'ensemble des informations accessibles via www.gleeph.net est
            fourni en l'état.
            <br />
            <br />
            F-451 ne donne aucune garantie et n'assume aucune responsabilité
            relative à l'usage, la copie, la transmission et à toute utilisation
            des informations, de quelque nature qu'elles soient, contenues sur
            le site
            <a href="https://relay.gleeph.com/">https://relay.gleeph.com/</a>.
            F-451 n'est responsable ni de l'exactitude, ni des erreurs, ni des
            omissions contenues sur ce site. L'utilisateur est seul responsable
            de l'utilisation de telles informations.  F-451 ne pourra être
            responsable pour quelque dommage que ce soit résultant d'une
            information contenue sur ce site. L'utilisateur s'engage à ne
            transmettre sur ce site aucune information pouvant entraîner une
            responsabilité civile ou pénale et s'engage à ce titre à ne pas
            divulguer via ce site des informations illégales, contraires à
            l'ordre public, aux bonnes mœurs ou diffamatoires. 
          </p>
          <p className="legalNoticeSubtitle">LIENS HYPERTEXTES :</p>
          <p className="legalNoticeText">
            L'insertion par un Utilisateur de liens hypertextes vers toute
            partie du Site{" "}
            <a href="https://relay.gleeph.com/">https://relay.gleeph.com/</a>{" "}
            est interdite, sauf autorisation préalable et écrite de la société
            F-451. Le Site{" "}
            <a href="https://relay.gleeph.com/">https://relay.gleeph.com/</a>
            contient des liens hypertextes vers d'autres sites. F-451 ne
            saurait, cependant, être tenu responsable du contenu des dits sites.
          </p>
        </div>
        {/* <div className="separateLineStyle" />
      <FooterB2B /> */}
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div className="separateLineStyle" />
        {/* <ReglementConcours /> */}
        <MentionsLegales />
      </section>
    </div>
  );
}
