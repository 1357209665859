import homePicture from "../images/placeholderB2B.svg";

const PlaceholderHome = ({ margin }) => {
  return (
    <div className="placeholder_div">
      <img className="placeholder" src={homePicture} alt="placeholder" />
    </div>
  );
};

export default PlaceholderHome;
