import React, { useEffect, useState } from "react";
import Autocompletion from "../components/Autocompletion";
import CoupDeCoeur from "../components/CoupDeCoeur";
import Header from "../puresComponents/Header";
import PlaceholderHome from "../puresComponents/PlaceholderHome";
import FooterGleeph from "../puresComponents/FooterGleeph";
import MentionsLegales from "../puresComponents/MentionsLegales";
import { BookConsumerHook } from "../stores/bookStore";
import { useNavigate } from "react-router-dom";
import text from "../utils/lang/lang";

const Search = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  /// Parametre tracking give by client
  let tmp_tracking_consent = params.get("tracking_consent");
  if (!["false", "true"].includes(tmp_tracking_consent)) {
    tmp_tracking_consent = "false";
  }

  const ean = params.get("ean") === null ? "" : params.get("ean");
  const author = params.get("authors") === null ? "" : params.get("authors");
  const type = author === "" ? "book" : "authors";

  const [focus, setFocus] = useState(false);

  const [{ searchTracking, resultAutocompletion, bookReco }, dispatch] =
    BookConsumerHook();

  useEffect(() => {
    if (bookReco.length > 0) {
      dispatch({
        type: "addData",
        newBookReco: [],
        newBookSearch: [],
        newBookAuthor: [],
        newBookSerie: [],
        newAuthorRef: [],
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
    }
  }, []);

  return (
    <>
      <Header />
      {!focus && (
        <div className="containerTitleSubtitleHome">
          <div className="title">{text.HOME_TITLE}</div>
          <div className="title">{text.HOME_TITLE_2}</div>
        </div>
      )}
      <section>
        <div style={focus ? { paddingTop: 26 } : null}>
          <Autocompletion
            navigate={navigate}
            searchTracking={searchTracking}
            resultAutocompletion={resultAutocompletion}
            dispatch={dispatch}
            setFocus={setFocus}
          />
        </div>
        <div className="separateLineStyle" />
      </section>
      <PlaceholderHome margin="24" />
      <section style={{ paddingBottom: 80 }}>
        <div>
          <div className="separateLineStyle" />
          <CoupDeCoeur scrollable={false} />
          <div className="separateLineStyle" />
          <FooterGleeph />
          <div className="separateLineStyle" />
          <MentionsLegales />
        </div>
      </section>
    </>
  );
};

export default Search;
