import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import React from "react";
import iconAuthor from "../images/iconAuthor.svg";
import iconAuthorMin from "../images/iconAuthorMin.svg";
import logo from "../images/logo.svg";
import DefaultCover from "../puresComponents/DefaultCover";
import ClilRectangle from "../puresComponents/ClilRectangle";

const icon = {
  mini: {
    author: iconAuthorMin,
  },
  normal: {
    author: iconAuthor,
  },
};

//Composant du Header de BookScreen avec le background
const BackGroundBookFull = (props) => {
  const { id_ean, image, title, edition, publishing_date, page, clil } = props;
  const cover = cst.WORK_PICTURE + image + cst.FIN_LIEN;

  return (
    <div className="backgroundTopBook">
      <div className="containerTopBook">
        <div className="containerBookCover">
          {image ? (
            <img src={cover} alt="Shelf Cover" className="sizeBookCover" />
          ) : (
            <DefaultCover
              title={title}
              style={"sizeBookCover"}
              type="bookDetail"
            />
          )}
        </div>
        <div className="containerInfoBook">
          <span className="titleShelf">{title}</span>
          <span className="infoBookPolice">
            <InfoBook
              edition={edition}
              publishing_date={publishing_date}
              page={page}
            />
          </span>
          {Selection.length === 0 && <ClilRectangle id={clil.id} />}
        </div>
      </div>
    </div>
  );
};

//Element pour gérer l'affichage des informations du livres
//On enlève edition/page vide ainsi que les dates en TS
const InfoBook = (props) => {
  const { edition, publishing_date, page } = props;
  const textPage = page > 0 ? page + " pages" : "";
  const textpublishing_date =
    publishing_date.length !== 4 ? "" : publishing_date;
  const textEdition = edition;

  let info = [textEdition, textpublishing_date, textPage];
  info = info.filter((el) => el !== "").filter((el) => el !== undefined);
  info = info.map((el, id) => (
    <span key={id}>
      {el}
      {info.length == parseInt(id) + 1 ? "" : ", "}
    </span>
  ));

  return info;
};

//Composant d'attente de remplissage du header de BookScreen
const LoadingBackGroundBook = (props) => {
  const { screen } = props;
  return (
    <div className="backgroundTopBook">
      <div className="containerTopBook">
        <div className="containerBookCover">
          <div className="sizeBookCover sizeBookCover-loading">
            <img
              src={logo}
              alt="Default Cover"
              className="bookscreen-cover-loading"
            />
          </div>
        </div>
        <div className="containerInfoBook ">
          <div>
            <div className="titleBook-loading-up" />
            <div className="titleBook-loading-down" />
          </div>
          <span className="backgroundbook-police-loading "></span>
          <div className="clilContainer-loading">
            <img src={icon[screen]["author"]} alt="Author Icon" />
            <span
              style={{ marginLeft: 6, color: "#D1C8B3" }}
              className="typeBookPolice"
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

//Component qui affiche le background de la fiche livre selon le chargement de la page
const BackGroundBook = (props) => {
  const {
    id_ean,
    image,
    title,
    edition,
    publishing_date,
    page,
    clil,
    loading,
  } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: 720px)` })) {
    screen = "mini";
  }
  if (loading) {
    return <LoadingBackGroundBook screen={screen} />;
  } else {
    return (
      <BackGroundBookFull
        id_ean={id_ean}
        screen={screen}
        image={image}
        title={title}
        edition={edition}
        publishing_date={publishing_date}
        page={page}
        clil={clil}
      />
    );
  }
};

export default BackGroundBook;
