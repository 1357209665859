import React, { useState, useRef } from "react";
import API from "../utils/apiHelper";
import auteurLogo from "../images/AuteurBlack.svg";
import themeLogo from "../images/TagBlack.svg";
import livreLogo from "../images/Livres.svg";
import loupe from "../images/loupe.svg";
import cross from "../images/close.svg";
import AutocompletionText from "../puresComponents/AutocompletionText";
import text from "../utils/lang/lang";
import cst from "../utils/constant";

const Autocompletion = (props) => {
  const { navigate, searchTracking, dispatch, setFocus } = props;
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [hoverInput, setHoverInput] = useState(false);
  const [hoverAutocompletion, setHoverAutocompletion] = useState(false);
  const [showAutoCompletion, setShowAutoCompletion] = useState(false);
  const [listAutoCompletion, setListAutoCompletion] = useState([]);
  const ref_input = useRef(null);

  function handleChange(event) {
    setValue(event.target.value);
    if (event.target.value.length >= 3) {
      //Call AutoComplete API if no letter write during 250ms
      const newTimer = setTimeout(async () => {
        const res = await API.fetchAutocomplete(event.target.value);

        //Show autocompletion only if they are result
        if (!showAutoCompletion && res.length > 0) setShowAutoCompletion(true);
        //Hide autocompletion if no result
        if (showAutoCompletion && res.length === 0)
          setShowAutoCompletion(false);

        fillAutoCompletion(res, event.target.value);
      }, 350);
      setTimer(newTimer);
    } else if (event.target.value.length < 3 && showAutoCompletion) {
      setShowAutoCompletion(false);
    }

    clearTimeout(timer);
  }

  function fillAutoCompletion(resAutoCompletion, value = "") {
    try {
      var tempListAutoCompletion = resAutoCompletion.map((el, id) => (
        <div
          className="containerAutoCompletion"
          onClick={() => {
            handleSelectAutocompletion(el.id_ean != null ? el.id_ean : el.name);
            navigate(
              `/${cst.ID_STORE}/datas/?book=${
                el.type === "book" ? el.id_ean : ""
              }&author=${el.type === "author" ? el.name : ""}&theme=${
                el.type === "theme" ? el.keyword : ""
              }`
            );
          }}
          key={id}
        >
          {el.type === "book" && (
            <img className="itemAutoCompletionImg" src={livreLogo} alt="item" />
          )}
          {el.type === "theme" && (
            <img className="itemAutoCompletionImg" src={themeLogo} alt="item" />
          )}
          {el.type === "author" && (
            <img
              className="itemAutoCompletionImg"
              src={auteurLogo}
              alt="item"
            />
          )}
          <AutocompletionText el={el} type={el.type} searchWord={value} />
        </div>
      ));

      dispatch({
        type: "autocompletionHistoric",
        newSearchTracking: [...searchTracking, value],
        newResultAutocompletion: resAutoCompletion.map((el) =>
          el.title != null ? el.title : el.name
        ),
      });
      setListAutoCompletion(tempListAutoCompletion);
    } catch {
      console.log("query has been cancelled");
    }
  }

  function handleFocus(event) {
    setFocus(true);
  }

  function handleFocusOut(event) {
    setFocus(false);
    if (!hoverAutocompletion) setShowAutoCompletion(false);
  }

  function handleSelectAutocompletion(subject) {
    setValue("");
    setShowAutoCompletion(false);
    setListAutoCompletion([]);
    dispatch({ type: "selectElementInAutocompletion", newSubject: subject });
  }

  function clearAutoCompletion() {
    setValue("");
    setShowAutoCompletion(false);
    setListAutoCompletion([]);
    dispatch({ type: "clearAutocompletionHistoric" });
    focusOnInput();
  }

  const focusOnInput = () => {
    ref_input.current.focus();
  };

  function handlerHover(event) {
    setHoverInput(true);
  }

  function handlerHoverOut(event) {
    setHoverInput(false);
  }

  return (
    <div className="autocomplete">
      <input
        id={showAutoCompletion ? "myBookInput" : "myBookInputShadow"}
        ref={ref_input}
        type="text"
        // onMouseEnter={handlerHover}
        // onMouseLeave={handlerHoverOut}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        // onBlur={() =>
        //   setTimeout(function () {
        //     handleFocusOut();
        //   }, 100)
        // }
        placeholder={text.HOME_INPUT}
        className="inputPolice"
        style={
          showAutoCompletion
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : null
        }
      />

      {value.length === 0 ? (
        <img src={loupe} className="autocomplete_svg" alt="loupe" />
      ) : (
        <img
          src={cross}
          className="autocomplete_svg"
          alt="clear"
          onFocus={handleFocus}
          onClick={() => {
            clearAutoCompletion();
          }}
        />
      )}

      {showAutoCompletion && (
        <div
          onMouseOver={() => setHoverAutocompletion(true)}
          onMouseOut={() => setHoverAutocompletion(false)}
          className="autocomplete-items"
        >
          {listAutoCompletion}
        </div>
      )}
    </div>
  );
};
export default Autocompletion;
