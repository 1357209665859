import CoupDeCoeur from "./CoupDeCoeur";
import NoRecoStock from "../images/NoRecoStock.svg";
import Button from "../puresComponents/Button";
import text from "../utils/lang/lang";

const ResultWithoutReco = (props) => {
  const { coupDeCoeur } = props;
  return (
    <div>
      <div className="resultRecoContainer">
        <img src={NoRecoStock} alt="reco" />
        <div className="TopTextContainer">
          <span className="TopTextReco">{text.TITLE_NO_RECO}</span>
        </div>
        <div className="SubTopTextContainer">
          <span className="footerTextPolice">{text.SUBTITLE_NO_RECO}</span>
        </div>
      </div>
      <div className="paddingBottomNoReco">
        <Button redirect={"/"} text={text.BACK_HOME_SCREEN} />
      </div>
      <div className="separateLineStyle" />
      <CoupDeCoeur heartBook={coupDeCoeur} scrollable={false} />
      <div className="separateLineStyle" />
    </div>
  );
};

export default ResultWithoutReco;
