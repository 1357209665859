// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HOME_TITLE: "Trouvez le prochain livre",
  HOME_TITLE_2: "qui vous transportera",
  HOME_SUBTITLE:
    "Et tentez de gagner un livre en effectuant votre première recherche :",
  HOME_INPUT: "Mot clé, titre ou auteur",
  LEGAL_MENTIONS: "Mentions légales",
  POLITIQUE: "Politique de Confidentialité",
  TITLE_COUP_DE_COEUR: "Nos coups de cœur du moment",
  TITLE_FOOTER_B2B: "Découvrez les catalogues",
  TITLE_FOOTER_GLEEPH: "Découvrez encore plus de lectures sur",
  ADD_BOOK:
    "Ajoutez un autre livre, mot-clé, auteur ou cliquez pour découvrir vos idées lecture sur-mesure.",
  GET_RECO: "SUIVANT",
  START_RECO: "C'EST PARTI",
  SELECT_AGE_TITLE: "À qui souhaitez-vous offrir un livre ?",
  SELECT_AGE_SUBTITLE:
    "Pour commencer, indiquez l’âge de la personne qui va recevoir ce cadeau :",
  SELECT_TYPE_TITLE: {
    theme: "Maintenant, dites-nous en plus sur ses goûts",
    author: "Quels sont ses auteurs favoris ?",
    book: "Quels sont ses livres préférés ?",
  },
  SHARE_LIST_RECO: "partager la liste",
  SHARE_TITLE: "Partagez cette liste d’idées",
  SHARE_SUB_TITLE:
    "Retrouvez cette liste dans vos mails en saisissant votre adresse e-mail ci-dessous :",
  SHARE_LINK_EXPORT: "partager le lien",
  SHARE_PDF_EXPORT: "exporter en pdf",
  SELECT_TYPE_SUBTITLE: {
    book: "Sélectionnez un ou plusieurs livres grâce à la recherche ci-dessous :",
    author:
      "Sélectionnez un ou plusieurs auteurs grâce à la recherche ci-dessous :",
    theme:
      "Sélectionnez un ou plusieurs thèmes dans la liste ci-dessous, ou utilisez la recherche :",
  },
  PLACEHOLDER_INPUT: {
    book: "Titre de livre...",
    author: "Nom d’auteur...",
    theme: "Thème, genre, mot clé, ...",
  },
  RECO_TITLE: "Voici nos idées de cadeaux pour cette personne :",
  TITLE_END: "Le festival est terminé !",
  SUBTITLE_END:
    "Pendant toute la durée du FIBD, le groupe 1 et Gleeph vous ont permis de découvrir des recommandations de lecture sur mesure. Découvrez maintenant tous les catalogues des éditions 1 et 2.",
  LOAD_BOOKS: "Nous interrogeons les stocks",
  SHARE_BUTTON: "recevoir ces recommandations",
  TITLE_NO_RECO: "Mmh, vous nous posez une colle !",
  SUBTITLE_NO_RECO:
    "Lancez une nouvelle recherche ou inspirez-vous des coups de cœur du magasin !",
  BACK_HOME_SCREEN: "Retour à la recherche",
  REGLEMENT_CONCOURS: "Réglement concours",
  TITLE_RUBRIQUE_RECOS: "Vous pourriez aussi aimer",
  TITLE_RUBRIQUE_SERIES: "Dans la même série",
  TITLE_RECOS_AUTHOR_1: "Dans ce magasin, écrit par",
  TITLE_RECOS_AUTHOR_2: "Dans ce magasin, pas de livre écrit par",
  TITLE_RECOS_THEME_1: "Dans ce magasin,",
  TITLE_RECOS_THEME_2: "sur le thème",
  TITLE_RECOS_BOOK: "Dans ce magasin :",
  CONCOUR_BUTTON: "participer au jeu concours",
  CONCOURS_TITLE: "Participez à notre jeu concours",
  CONCOURS_SUBTITLE: "2 livres à gagner par mois.",
  CONCOURS_REGISTERED_TITLE: "Merci pour votre participation !",
  CONCOURS_SUB_REGISTERED_TITLE_1:
    "Nous vous recontacterons après tirage au sort si vous êtes l'un des gagnants.",
  CONCOURS_SUB_REGISTERED_TITLE_2:
    "Vous pourrez aussi tenter à nouveau votre chance le mois prochain.",
  CONCOURS_EMAIL_SEND_TITLE: "Oups, vous avez déjà participé !",
  CONCOURS_SUB_EMAIL_SEND_TITLE_1:
    "Une seule participation est autorisée chaque mois par personne.",
  CONCOURS_SUB_EMAIL_SEND_TITLE_2: "Retentez votre chance le mois prochain!",
  CONCOURS_BANDEAU_1 : "Prix RELAY des Voyageurs Lecteurs",
  CONCOURS_BANDEAU_2 : "2 iPhone, 2 Polaroïd, 1 an de livres à gagner",
  CONCOURS_BANDEAU_3 : "du 2 avril au 2 Juin 2024",
  VOTED : "Voter",
  CLOSED : "Fermer",
  CONCOUR_MODAL_1 : " Votez et tentez de gagner 2 iPhone,  2 Polaroid et 1 an de livres",
  PARTICIPATE_CONCOURS : "participer au vote"
  
};
