import React, { useEffect, useState } from "react";
import FooterGleeph from "../puresComponents/FooterGleeph";
import helpers from "../utils/apiHelper";
import { clearImgs } from "../utils/functions";
import ResultWithReco from "../components/ResultWithReco";
import ResultWithoutReco from "../components/ResultWithoutReco";
import { BookConsumerHook } from "../stores/bookStore";
import MentionsLegales from "../puresComponents/MentionsLegales";
import Header from "../puresComponents/Header";

const Datas = () => {
  const [
    {
      bookReco,
      bookSearch,
      bookAuthor,
      bookSerie,
      authorRef,
      glidRef,
      themeRef,
      search,
      typeOfSearch,
      needLoads,

      searchTracking,
      resultAutocompletion,
      subject,
      originWebsiteParent,
      tracking_consent,
    },
    dispatch,
  ] = BookConsumerHook();

  const params = new URLSearchParams(window.location.search);
  const [getResponseAPI, setGetResponseAPI] = useState(false);
  const [coupDeCoeur, setCoupDeCoeur] = useState([]);
  const [loading, setLoading] = useState(needLoads ? true : false);

  useEffect(() => {
    // Check Store and use it if not empty
    if (needLoads) {
      const fetchData = async () => {
        const book = params.get("book");
        const author = params.get("author");
        const theme = params.get("theme");

        const res = await helpers.fetchGetDatas(
          book,
          author,
          theme,
          searchTracking,
          resultAutocompletion,
          subject,
          "true",
          {
            timeout: 5000,
          }
        );

        if (typeof res === "object" && "recos" in res) {
          const recos_w_validurl = await clearImgs(res["recos"]["recos"], true);

          let same_author_w_validurl = [];
          let same_serie_w_validurl = [];

          if (res["books_by_author"].length !== 0) {
            same_author_w_validurl = await clearImgs(
              res["books_by_author"]["authors_books"]
            );
          }

          if (res["books_by_serie"].length !== 0) {
            same_serie_w_validurl = await clearImgs(
              res["books_by_serie"]["series_books"]
            );
          }

          let tempSearch = book;
          let tempTypeOfSearch = "book";
          let input_book = res["recos"]["input_book"];
          if (book !== "") {
            input_book = input_book[0];
          }
          if (author !== "") {
            tempTypeOfSearch = "author";
            tempSearch = author;
          }
          if (theme !== "") {
            tempTypeOfSearch = "theme";
            tempSearch = theme;
          }

          addStateAndStore(
            recos_w_validurl,
            input_book,
            same_author_w_validurl,
            same_serie_w_validurl,
            res["author_search"],
            book,
            themeRef,
            tempSearch,
            tempTypeOfSearch
          );
          dispatch({ type: "clearAutocompletionHistoric" });
        }
      };
      fetchData();
    }
  }, []);

  async function getCoupDeCoeur() {
    const res = await helpers.fetchCoupDeCoeur();
    setCoupDeCoeur(res);
  }

  function sendUserTracking(element = null, type = "from_reco_to_book_detail") {
    helpers.fetchTrackSearch(type, element);
  }

  function addStateAndStore(
    book_reco,
    book_search,
    book_author,
    book_series,
    authorRef,
    glidRef,
    themeRef,
    search,
    typeOfSearch
  ) {
    const bk_search = book_search === undefined ? [] : book_search;
    const auth_search = authorRef === undefined ? [] : authorRef;
    if (
      book_reco != null &&
      book_reco !== "Mot Invalide" &&
      book_reco.length !== 0
    ) {
      dispatch({
        type: "addData",
        newBookReco: book_reco.filter((book) => book.imgs),
        newBookSearch: bk_search,
        newBookAuthor: book_author.filter((book) => book.imgs),
        newBookSerie: book_series.filter((book) => book.imgs),
        newAuthorRef: auth_search,
        newGlidRef: glidRef,
        newThemeRef: themeRef,
        newSearch: search,
        newTypeOfSearch: typeOfSearch,
        newNeedLoads: false,
      });
    } else {
      getCoupDeCoeur();
    }
    setGetResponseAPI(true);
    setLoading(false);
  }

  return (
    <div>
      <Header withBack={true} />
      <section style={{ paddingBottom: 80 }}>
        {getResponseAPI && bookReco.length === 0 && !loading ? (
          <ResultWithoutReco coupDeCoeur={coupDeCoeur} />
        ) : (
          <div>
            <ResultWithReco
              bookStock={bookReco}
              loading={loading}
              typeOfSearch={typeOfSearch}
              search={search}
              bookAuthor={bookAuthor}
              bookSearch={bookSearch}
              bookSerie={bookSerie}
              glidRef={glidRef}
              authorRef={authorRef}
              themeRef={themeRef}
              sendUserTracking={sendUserTracking}
            />
            <div className="separateLineStyle" />
          </div>
        )}
        <FooterGleeph />
        <div className="separateLineStyle" />
        <MentionsLegales />
      </section>
    </div>
  );
};

export default Datas;
