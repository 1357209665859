import { Player, Controls } from "@lottiefiles/react-lottie-player";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";

const Loading = (props) => {
  const { text } = props;
  return (
    <div className="TopWaitTextReco">
      <span className="title-client">{text}</span>
      <Player
        autoplay
        loop
        src={animationLoading}
        speed={0.8}
        style={{ height: "80px", width: "80px" }}
      >
        <Controls visible={false} />
      </Player>
    </div>
  );
};

export default Loading;
